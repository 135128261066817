import React, { Component, useState } from "react";
import "./login.css";
import { Button, Form, InputGroup, FormControl } from "react-bootstrap";
import { Home } from "../Home/Home";
import { Redirect } from "react-router-dom";
import { SubmitButton } from "../styled";
import { Alert } from "@mui/material";
import Logo from "../../pictures/redLogo.svg";

export class LoginForm extends Component {
  static displayName = LoginForm.name;

  constructor(props) {
    super(props);
    this.state = {
      Username: "",
      Password: "",
      redirect: false,
      errorMessage: "",
      status: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeUsername(e) {
    this.setState({ Username: e.target.value });
  }

  handleChangePassword(e) {
    this.setState({ Password: e.target.value });
  }

  login(username, password) {
    localStorage.removeItem("token");
    this.setState({ status: "loading" });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    };
    if (username.length === 0 || password.length === 0) {
      this.setState({ errorMessage: "Խնդրում ենք լրացրեք բոլոր դաշտերը" });
    } else {
      const user = fetch("api/AdminAuthenticate/Login", requestOptions)
        .then(async (response) => {
          if (response.status === 401) {
            this.setState({ errorMessage: "Login or password error" });
          } else if (!response.ok) {
            const test = await response.json();
            this.setState({ errorMessage: test.message });
            // throw new Error("HTTP status " + response.status)
            return false;
          }
          else {
            const data = await response.json();
            let token = response.headers.get("authorization");
            localStorage.setItem("icon", data.model.logoUrl);
            localStorage.setItem("userId", data?.model?.id);
            localStorage.setItem("role", data?.model?.role);
            localStorage.setItem("token", token);
            window.location.href = "/";
          }
        })
        .then(() => {
          this.setState({ redirect: true });
        });

      return true;
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let isLogged = this.login(this.state.Username, this.state.Password);
    console.log(isLogged);
    if (isLogged) {
      this.setState({ redirect: true });
    }
  }

  render() {
    return (
      <>
        {this.state.errorMessage && (
          <Alert severity="error">{this.state.errorMessage}</Alert>
        )}

        <div className="LoginForm">
          <div className="content">
            <Form
              className={
                "wrap d-flex align-items-center justify-content-center login"
              }
              onSubmit={this.handleSubmit}
            >
              <img
                src={Logo}
                style={{ marginBottom: "60px", maxWidth: "80%" }}
              />
              <Form.Group className="mb-3 inputForm" controlId="formGroupEmail">
                <Form.Label>Գաղտնանուն</Form.Label>
                <FormControl
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={this.state.Username}
                  onChange={(e) => this.handleChangeUsername(e)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 inputForm"
                controlId="formGroupPassword"
              >
                <Form.Label>Գաղտնաբառ</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={this.state.Password}
                  onChange={(e) => this.handleChangePassword(e)}
                />
              </Form.Group>
              <SubmitButton style={{ marginTop: "50px" }} type={"submit"}>
                Մուտք
              </SubmitButton>
              <br />
            </Form>
          </div>
        </div>
      </>
    );
  }
}
