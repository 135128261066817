import React from 'react'


 const Logo = () => {
    return(
        <svg width="74" height="30" viewBox="0 0 74 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2515 6.19056C15.6195 6.19056 17.4296 7.08919 18.6819 8.88644C19.957 10.6837 20.3896 12.8249 19.9797 15.31C19.5471 17.7285 18.5111 19.7477 16.8718 21.3674C15.2552 22.965 13.2629 23.7638 10.8949 23.7638C8.64081 23.7638 7.01283 22.9761 6.011 21.4007L5.66946 23.2978H0L4.20087 0H9.87033L8.43589 8.0211C9.73372 6.80074 11.3389 6.19056 13.2515 6.19056ZM14.3103 14.9772C14.5152 13.8677 14.3444 12.9913 13.798 12.3478C13.2515 11.6822 12.4546 11.3494 11.4072 11.3494C10.3599 11.3494 9.43772 11.6822 8.64081 12.3478C7.86667 12.9913 7.37713 13.8677 7.17221 14.9772C6.99006 16.0866 7.17221 16.9741 7.71867 17.6398C8.26512 18.2832 9.07342 18.605 10.1436 18.605C11.1909 18.605 12.1017 18.2832 12.8758 17.6398C13.65 16.9741 14.1281 16.0866 14.3103 14.9772Z" fill="white"/>
            <path d="M34.1272 6.65651H39.7967L36.7912 23.2978H31.1217L31.4291 21.6004C30.1313 23.0426 28.4692 23.7638 26.4427 23.7638C24.5984 23.7638 23.1754 23.1425 22.1736 21.8999C21.1945 20.6352 20.8985 18.8712 21.2856 16.608L23.0957 6.65651H28.7652L27.0916 15.9091C26.955 16.7966 27.0803 17.4623 27.4673 17.906C27.8544 18.3276 28.435 18.5384 29.2092 18.5384C30.0744 18.5384 30.7916 18.2832 31.3608 17.7729C31.9528 17.2626 32.3399 16.5082 32.522 15.5097V15.543L34.1272 6.65651Z" fill="white"/>
            <path d="M46.3267 23.7638C42.547 23.7638 40.0424 22.3992 38.8129 19.67L43.7993 17.3069C44.3002 18.5717 45.2451 19.204 46.6341 19.204C47.6359 19.204 48.1823 18.9378 48.2734 18.4053C48.3417 18.0724 48.0913 17.7951 47.522 17.5732C46.9756 17.3291 46.2925 17.0961 45.4728 16.8743C44.6759 16.6524 43.8904 16.3639 43.1163 16.0089C42.3421 15.6317 41.7273 15.0437 41.272 14.2449C40.8394 13.424 40.7255 12.4144 40.9304 11.2162C41.2264 9.5521 42.0917 8.29845 43.5261 7.4553C44.9605 6.61214 46.543 6.19056 48.2734 6.19056C49.8445 6.19056 51.222 6.51229 52.406 7.15575C53.6127 7.77703 54.4893 8.68675 55.0358 9.88492L50.2202 12.1481C49.8103 11.1497 49.1045 10.6393 48.1026 10.6171C47.2374 10.5728 46.7365 10.8723 46.5999 11.5158C46.5316 11.8708 46.691 12.1703 47.0781 12.4144C47.4651 12.6363 47.966 12.8027 48.5808 12.9136C49.1956 13.0246 49.8559 13.2132 50.5617 13.4794C51.2675 13.7235 51.9051 14.0231 52.4743 14.3781C53.0663 14.7331 53.4989 15.2989 53.7721 16.0755C54.0681 16.8299 54.125 17.7396 53.9429 18.8046C53.6241 20.5132 52.7589 21.7668 51.3472 22.5656C49.9356 23.3644 48.262 23.7638 46.3267 23.7638Z" fill="white"/>
            <path d="M67.4767 6.65651H74L65.325 22.7986C63.8451 25.5721 62.1602 27.5025 60.2703 28.5897C58.3805 29.6991 56.1719 30.154 53.6446 29.9543L54.6009 24.7622C55.8304 24.7622 56.7525 24.6069 57.3673 24.2963C57.9821 24.0078 58.5513 23.4531 59.075 22.6321L55.2498 6.65651H61.2608L63.0368 16.2752L67.4767 6.65651Z" fill="white"/>
        </svg>
    )
 }
 export default Logo;
