import React, { Component } from 'react';
import { IsLoggedIn } from "../Login/Authentication";
import { Redirect } from "react-router-dom";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoggedIn: false,
        };
    }

    componentDidMount() {
        let isLogged = IsLoggedIn();
        IsLoggedIn().then(res => {
            isLogged = res.data;
        });
        if (isLogged == true) {
            this.setState({ isLoggedIn: true }, () => {
                console.log("Logged in");
            });
        }
    }
    render(){
        return (
            <div>
                {/* <button   onClick={() => sessionStorage.removeItem('token')}>logOut</button> */}
            </div>
        );
    }
}

