import React, {useEffect, useState} from "react";
import "./styled.scss"
import {Formik} from "formik";
import {useHistory} from "react-router";
import {Input, PageTitle, SubmitButton, Label, WrapForm, FormBody, FormHeader, Loading} from "../../components/styled";
import {Alert} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const AddManagers = ({edit}) => {
    const [id, setId] = useState(window.location.hash.slice(1))
    let token = localStorage.getItem('token');
    const [manager, setManager] = useState({})
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    useEffect(async () => {
        if(edit){

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };
            const getManager = await fetch(`api/Manager/GetManagerById?managerId=${id}`, requestOptions)
            if(getManager.status === 401){
                localStorage.clear();
                window.location.reload()
            }
            if (getManager.status === 500) {
                alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
            }
            const managerData = await getManager.json();
            getManager.status === 200 && setManager(managerData.model)
        }
    }, [id])
    const history = useHistory()
    return (
        <>
            {status === "error" ?
                <Alert severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress color="inherit"/>
                </Loading>
            }
            <Formik
                enableReinitialize
                initialValues={{
                    firstName: manager?.firstName || '',
                    lastName: manager?.lastName || '',
                    username: manager?.userName || '',
                    phoneNumber: manager?.phoneNumber || '',
                    ManagerId: id
                }}
                onSubmit={async (values) => {
                    setStatus("loading")
                    const requestOptions = {
                        method: edit ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        mode: 'cors',
                        body: JSON.stringify(values)
                    };
                    const url = edit ? 'api/AdminAuthenticate/UpdateManager' : 'api/AdminAuthenticate/RegisterManager'
                    const request = await fetch(url, requestOptions)
                    const response = await request.json()
                    if (response.status === "Success") {
                        history.push('/managers')
                        setStatus('success')
                    } else if(request.status === 500) {
                        setStatus("error")
                        setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy-ի հետ։")
                    }else {
                        setStatus('error')
                        setErrorMessage(response?.message || response?.title)
                    }
                }}>
                {props => (
                    props?.values &&
                    <WrapForm className={"manager"} onSubmit={props.handleSubmit}>
                        <FormHeader>
                            <PageTitle>{edit ? "Փոփոխել մենեջերի հաշիվը" : "Ավելացնել նոր մենեջեր"}</PageTitle>
                            <SubmitButton type={"submit"}>{edit ? "Փոփոխել" : "Ավելացնել"}</SubmitButton>
                        </FormHeader>
                        <FormBody maxWidth={"1000px"}>
                            <Label htmlFor="firstName">Անուն
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.firstName}
                                    name={"firstName"}
                                    placeholder={"Մուտքագրեք մենեջերի անունը"}
                                    type="text"/>
                            </Label>
                            <Label htmlFor="username">Գաղտնանուն
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.username}
                                    name={"username"}
                                    placeholder={"Մուտքագրեք գաղտնանունը"}
                                    type="text"/>
                            </Label>
                            <Label htmlFor="lastName">Ազգանունը
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.lastName}
                                    name={"lastName"}
                                    placeholder={"Մուտքագրեք մենեջերի ազգանունը"}
                                    type="text"
                                />
                            </Label>
                            {edit ?
                            <Label htmlFor={"oldPassword"}>հին Գաղտնաբառ
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    name={"oldPassword"}
                                    placeholder={"Մուտքագրեք հին գաղտնաբառը"}
                                    type="password"/>
                            </Label>
                                :
                                <Label htmlFor={"password"}>Գաղտնաբառ
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        // value={props.values.password}
                                        name={"password"}
                                        placeholder={"Մուտքագրեք գաղտնաբառը"}
                                        type="password"/>
                                </Label>
                            }
                            <Label htmlFor="phoneNumber">Հեռախոսահամար
                                <Input
                                    pattern={'374[0-9]{8}'}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.phoneNumber}
                                    name={"phoneNumber"}
                                    placeholder={"374********"}
                                    type="tel"/>
                            </Label>
                            {edit &&
                            <Label htmlFor="password">Հաստատել գաղտնաբառը
                                <Input
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    name={"password"}
                                    placeholder={"Մուտքագրեք նոր գաղտնաբառը"}
                                    type="password"/>
                            </Label>}
                        </FormBody>
                    </WrapForm>
                )}
            </Formik>
        </>
    )
};

export default AddManagers