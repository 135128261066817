import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { LoginForm } from "./components/Login/Login";
import "./custom.css";
import Managers from "./Pages/Managers/Managers";
import AddManagers from "./Pages/AddManagers";
import Networks from "./Pages/Networks";
import AddNewBranch from "./Pages/Branches/addNew";
import AddNewNetwork from "./Pages/Networks/addNew";
import Categories from "./Pages/Categories";
import Users from "./Pages/Users";
import Items from "./Pages/Items";
import AddNewItem from "./Pages/Items/addNew";
import { Redirect } from "react-router-dom";
import Ads from "./Pages/Ads";
import Banners from "./Pages/Banners";
import Finances from "./Pages/Finances";
import Rating from "./Pages/Rating";
import Landing from "./Pages/Landing";
import History from "./Pages/History";
import HistoryByUsers from "./Pages/HistoryByUsers";
import Privacy from "./Pages/Privacy/Privacy";
import SuccessEn from "./Pages/success/successEn";
import SuccessHy from "./Pages/success/successHy";
import SuccessRu from "./Pages/success/successRu";
import TellcellRefounds from "./components/TellcellRefounds";

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem("token"),
            admin: window.location.origin.includes("admin"),
            role: localStorage.getItem("role"),
        };
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.setState({ token: localStorage.getItem("token") });
    }

    logout() {
        const { history } = this.props;
        this.setState({ token: "" });
        localStorage.clear();
        history && history.push("/");
    }

    render() {
        return (
            <div>
                {this.state.admin ? (
                    this.state.token ? (
                        <>
                            <Layout logOut={this.logout}>
                                <Route exact path="/login">
                                    <Redirect to="/finances" />
                                </Route>
                                <Route exact path="/login">
                                    <Redirect to="/finances" />
                                </Route>
                                {this.state.role === "Manager" ? (
                                    <Route exact path="/" component={() => <Networks />} />
                                ) : (
                                    <Route exact path="/" component={() => <Finances />} />
                                )}

                                <Route exact path="/privacy" component={() => <Privacy />} />
                                <Route exact path="/history" component={() => <History />} />
                                <Route
                                    exact
                                    path="/historyByUsers"
                                    component={() => <HistoryByUsers />}
                                />
                                <Route exact path="/finance" component={() => <Finances />} />
                                <Route exact path="/managers" component={() => <Managers />} />
                                <Route
                                    exact
                                    path="/addManager"
                                    component={() => <AddManagers />}
                                />
                                <Route
                                    path="/editManager"
                                    component={() => <AddManagers edit={true} />}
                                />
                                <Route
                                    path="/editNetworks"
                                    component={() => <AddNewNetwork edit={true} />}
                                />
                                <Route
                                    path="/editBranches"
                                    component={() => <AddNewBranch edit={true} />}
                                />
                                <Route exact path="/networks" component={() => <Networks />} />
                                <Route
                                    path="/networks/addNew"
                                    component={() => <AddNewNetwork />}
                                />
                                <Route
                                    path="/branches/addNew"
                                    component={() => <AddNewBranch />}
                                />
                                <Route
                                    path="/items/categories"
                                    component={() => <Categories />}
                                />
                                <Route
                                    path="/networks/categories"
                                    component={() => <Categories />}
                                />
                                <Route path="/users" component={() => <Users />} />
                                <Route exact path="/items" component={() => <Items />} />
                                <Route path="/items/addNew" component={() => <AddNewItem />} />
                                <Route path="/editItem" component={() => <AddNewItem edit />} />
                                <Route path="/ads" component={() => <Ads />} />
                                <Route path="/banners" component={() => <Banners />} />
                                <Route path="/finances" component={() => <Finances />} />
                                <Route path="/rating" component={() => <Rating />} />
                                <Route path="/tellRef" component={() => <TellcellRefounds />} />
                            </Layout>
                        </>
                    ) : (
                        <>
                            <Route path="/privacy" component={() => <Privacy />} />
                            <Route exact path="/" component={LoginForm} />
                            <Route path="/succsessEn1312023" component={() => <SuccessEn />} />
                            <Route path="/succsessHy1312023" component={() => <SuccessHy />} />
                            <Route path="/succsessRu1312023" component={() => <SuccessRu />} />
                        </>
                    )
                ) : (
                    <>
                        <Route path="/" component={() => <Landing />} />
                        {/*<Route  path="/" render={() => {window.location.href = "/LandingPage/page32471078.html"}} />*/}
                    </>
                )}
            </div>
        );
    }
}
