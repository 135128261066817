import React, {useEffect, useState} from "react";
import {
    DatePickerInput,
    Dropdown,
    FormBody,
    FormHeader,
    Input,
    Label, Loading,
    PageTitle,
    SubmitButton,
    SubTitle, Trash,
    WrapForm
} from "../../components/styled";
import moment from "moment"
import {AdComponent, SliderWrapper} from "./styled";
import {Field, Formik} from "formik";
import imageUpload from "../../pictures/imageUpload.svg";
import trash from "../../pictures/trash.png";
import {Alert, Modal} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Ads = () => {
    let token = localStorage.getItem('token');
    const [ads, setAds] = useState()
    const [ad, setAd] = useState()
    const [models, setModels] = useState()
    const [modelsDropdown, setModelsDropdown] = useState(false)
    const [branchesDropdown, setBranchesDropdown] = useState(false)
    const [selectedModel, setSelectedModel] = useState()
    const [selectedBranch, setSelectedBranch] = useState()
    const [pic, setPic] = useState()
    const [date, setDate] = useState()
    const [status, setStatus] = useState("default")
    const [open, setOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const role = localStorage.getItem('role')
    const userId = localStorage.getItem("userId")
    const getAdds = async (value) => {
        if ((value?.networkId || selectedModel?.networkId) || (role === "Network" && userId)) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };
            const getAds = await fetch(`api/Story/GetStories?networkId=${role === "Network" ? userId : (value?.networkId || selectedModel?.networkId)}`, requestOptions)
            if(getAds.status === 401){
                localStorage.clear();
                window.location.reload()
            }
            const adsData = await getAds.json();
            getAds.status === 200 && setAds(adsData.models)
        }
    }
    useEffect(async () => {
        await getAdds()
        const modelsRequestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const getModels = await fetch(`api/Network/GetModelsForDropDown`, modelsRequestOptions)
        if(getModels.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        if (getModels.status === 500) {
            alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
        }
        const modelsData = await getModels.json();
        getModels.status === 200 && setModels(modelsData.models)
    }, [])

    const deleteAd = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deleteAd = await fetch(`api/Story/DeleteStory?id=${id}`, requestOptions)
        deleteAd.status === 200 && getAdds()
        setOpen(false)
    }

    const previewFile = () => {
        let preview = document.getElementById('uploadImg');
        let file = document.getElementById('pic').files[0];
        let reader = new FileReader();
        reader.onloadend = function (e) {
            preview.src = reader.result;
            setPic(reader.result.split(',')[1]);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = '';
        }
    }

    const selectType = (value, type) => {
        if (type === 'branch') {
            setSelectedBranch(value)
            setBranchesDropdown(false)
        } else {
            setSelectedModel(value)
            setModelsDropdown(false)
            getAdds(value)
        }
    }
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
    };
    const initialValues = {
        branchId: "",
        worksTil: "",
        pic: ""
    }
    return (
      <>
          <Modal
              open={open}
              onClose={()=>setOpen(false)}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
          >
              <div className={"modalWindow"}>
                  <div className="modalBox">
                      {`Ջնջե՞լ գովազդը`}
                      <div>
                          <SubmitButton className='addNew' onClick={()=>deleteAd(ad?.id)}>Ջնջել</SubmitButton>
                          <SubmitButton className='addNew cancelButton' onClick={()=>setOpen(false)}>Զեղարկել</SubmitButton>
                      </div>
                  </div>
              </div>
          </Modal>
        {role === "Network" ?
            <>
                <PageTitle>Գովազդ</PageTitle>
                <FormBody margin={"30px 0 70px"}>
                        {ads?.length > 0 ? ads?.map(ad =>
                            <AdComponent>
                                <img src={ad.picUrl}/>
                                <Trash onClick={() => {setAd(ad); setOpen(true)}}>
                                    <img src={trash}/></Trash>
                            </AdComponent>
                        ) : <AdComponent banner>
                            <div/>
                        </AdComponent>}
                </FormBody>
            </>
            :
            <>
                <PageTitle smallDistance>Գովազդներ</PageTitle><div></div>
                <Label dropdown className={`select ${modelsDropdown && "opened"}`} htmlFor="type">
                    <span>Ցանց</span>
                    <Input
                        readOnly={true}
                        onClick={() => setModelsDropdown(!modelsDropdown)}
                        value={selectedModel?.networkName}
                        name={"type"}
                        type="text"
                        placeholder="Ընտրեք ցանցը"
                    />
                    {modelsDropdown && models &&
                    <Dropdown>
                        {models?.map(model =>
                            <li data-key={0} value={0}
                                onClick={(e) => selectType(model)}>{model.networkName}
                            </li>
                        )}
                    </Dropdown>}
                </Label>
               {selectedModel &&
                    <>
                        <FormBody margin={"30px 0 70px"}>
                                {ads?.length > 0 ? ads?.map(ad =>
                                    <AdComponent small>
                                        <img src={ad.picUrl}/>
                                        <Trash onClick={() => {setAd(ad); setOpen(true)}}>
                                            <img src={trash}/></Trash>
                                    </AdComponent>
                                ) : <AdComponent small>
                                    <div/>
                                </AdComponent>}
                        </FormBody>
                        {console.log('status',status)}
                        {status === "error" ?
                            <Alert severity="error">
                                {errorMessage}
                            </Alert>
                            : status === "loading" &&
                            <Loading>
                                <CircularProgress color="inherit"/>
                            </Loading>
                        }
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={async (values,{ resetForm }) => {
                                setStatus("loading")
                                values["pic"] = pic;
                                values["branchId"] = selectedBranch && selectedBranch[0];
                                values["worksTil"] = date;
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    },
                                    body: JSON.stringify(values)
                                };
                                const addedAd = await fetch(`api/Story/CreateStory`, requestOptions);
                                if (addedAd.status === 200) {
                                    setStatus('success')
                                    setSelectedBranch(null)
                                    setStatus("default")
                                    getAdds()
                                } else if (addedAd.status === 500) {
                                    setStatus("error")
                                    setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
                                } else {
                                    setStatus('error')
                                    const addsData = await addedAd.json();
                                    setErrorMessage(addsData?.message || addsData?.title)
                                }


                            }}>
                            {props =>
                                <WrapForm onSubmit={props.handleSubmit}>
                                    <FormHeader>
                                        <h3>Ավելացնել նոր գովազդ</h3>
                                    </FormHeader>
                                    <div className={"d-flex"} style={{marginTop: '30px'}}>
                                        <div style={{marginRight: "20px"}}>
                                            <SubTitle style={{width: "100%", marginBottom: "20px"}}>Գովազդի
                                                նկար</SubTitle>
                                            <Label imageUpload ad>
                                                <Field
                                                    name={'pic'}
                                                    id={'pic'}
                                                    type={'file'}
                                                    onChange={() => {
                                                        previewFile();
                                                    }}
                                                    value={''}
                                                />
                                                {console.log('err', status, status !== "error" )}
                                                <img src={(status === "success" || status === "default") ? imageUpload : ""} id="uploadImg" alt="upload"/>
                                            </Label>
                                        </div>
                                        <FormBody noWrap maxWidth={"500px"}>
                                            <SubTitle style={{width: "100%"}}>Ընդհանուր ինֆորմացիա</SubTitle>
                                            <Label dropdown className={`select ${modelsDropdown && "opened"}`}
                                                   htmlFor="type">
                                                <span>Մասնաճյուղ</span>
                                                <Input
                                                    readOnly={true}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    onClick={() => setBranchesDropdown(!branchesDropdown)}
                                                    value={(status === "success") ? "" : selectedBranch && selectedBranch[1]}
                                                    name={"type"}
                                                    type="text"
                                                />
                                                {branchesDropdown && models &&
                                                <Dropdown>
                                                    {Object.entries(selectedModel?.branches)?.map((branch) =>
                                                        <li data-key={0} value={0}
                                                            onClick={(e) => selectType(branch, 'branch')}>{branch[1]}
                                                        </li>
                                                    )}
                                                </Dropdown>}
                                            </Label>
                                            {console.log('date', date, status)}

                                            <Label htmlFor="firstName">Գործում է մինչև
                                                <DatePickerInput
                                                    format={"DD.MM.YYYY"}
                                                    suffixIcon={null}
                                                    placeholder={''}
                                                    id="date"
                                                    value={status === "success" ? null : moment(date)}
                                                    disabledDate={(current) => current < moment().startOf('day')}
                                                    onChange={(e,dateString) => {
                                                        setDate(moment(dateString, "DD/MM/YYYY").format('YYYY-MM-DD'))
                                                    }}
                                                />
                                            </Label>
                                        </FormBody>
                                    </div>
                                    <SubmitButton distance type={"submit"}>{"Ավելացնել"}</SubmitButton>
                                </WrapForm>
                            }
                        </Formik>
                    </>
                    }
                </>}
        </>
    )
};

export default Ads