import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Table, Tag } from 'antd';
import s from './style.module.scss'
const { Column } = Table;

function TellcellRefounds() {
    const ref = useRef();
    const [load, setLoad] = useState(false)
    const [loadRefresh, setLoadRefresh] = useState(false)
    const [data, setData] = useState([])
    const [sum, setSum] = useState(0)
    const [page, setPage] = useState(1)
    let token = localStorage.getItem('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token
    }
    const getCSV = async () => {
        setLoad(true)
        const csv = await fetch(`api/Refunds/csv`, { headers })
        .then(res => res.text()).then(data => data)
        .catch(() => setLoad(false))
        const blobFile = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobFile);
        ref.current.href = url
        !load && ref.current.click();
        setLoad(false)
    }
    const getData = async () => {
        setLoadRefresh(true)
        await fetch(`api/Refunds?PageIndex=${page}&PageSize=10`, { headers })
        .then(res => res.json()).then(data => {
            setData(data.items)
            setLoadRefresh(false)
        })
        .catch(() => setLoadRefresh(false))
    }
    const clearData = async (e) => {
        e.preventDefault();
        const answer = window.confirm('Համոզվա՞ծ եք')
        if (answer) {
            await fetch(`api/Refunds/clear`, {
                method: 'DELETE', headers
            }).then(res => res.text()).then(_ => setData([])).catch(() => alert('Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։'))
        }
    }
    const getSum = async () => {
        await fetch(`api/Refunds/sum`, { headers }).then(res => res.text()).then(data => setSum(data)).catch(() => alert('Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։'))
    }
    useEffect(() => {
        getData()
        getSum()
    }, [page])
    
    return (
        <form className={s.form} onSubmit={clearData}>
            <div className={s.line1}>
                <span className={s.count}>{sum}</span>
                <div className={s.btns}>
                    <button type='button' className={s.download} onClick={getData}>
                        {loadRefresh ? 'Սպասեք...' : 'Թարմացնել'}
                    </button>
                    <button type='button' className={s.download} onClick={getCSV}>
                        <a ref={ref} download='refunds.csv'>{load ? 'Սպասեք...' : 'Ներբեռնել CSV'}</a>
                    </button>
                    <button type="submit" className={s.submit}>
                        Մաքրել
                    </button>
                </div>
            </div>
            <Table className={s.table} dataSource={data} onChange={e => setPage(e.current)}>
                <Column
                    align='center'
                    title="Վճարման id"
                    dataIndex="paymentId"
                    key="paymentId"
                    render={(id) => (
                        <Tag className={s.li} key={id}>
                            {id}
                        </Tag>
                    )}
                />
                <Column
                    align='center'
                    title="Գումար"
                    dataIndex="amount"
                    key="amount"
                    render={(amount) => (
                        <Tag className={s.li} key={amount}>
                            {amount}
                        </Tag>
                    )}
                />
                <Column
                    align='center'
                    title="Վերադարձի գումար"
                    dataIndex="refundAmount"
                    key="refundAmount"
                    render={(ref_amount) => (
                        <Tag className={s.li} key={ref_amount}>
                            {ref_amount}
                        </Tag>
                    )}
                />
                <Column
                    align='center'
                    title="Ամսաթիվ"
                    dataIndex="date"
                    key="date"
                    render={(date) => (
                        <Tag className={s.li} key={date}>
                            {new Date().toUTCString(date)}
                        </Tag>
                    )}
                />
            </Table>

        </form>
    )
}

export default TellcellRefounds