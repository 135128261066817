import React from "react";
import { busy } from "./busyLogo";
import { kor } from "./kor";
import { img } from "./success";
import { useEffect } from "react";
const SuccessEn = () => {
  // useEffect(() => {
  //   document.getElementsByClassName("wrapper")[0].style.display =
  //     "none";
  //   return () => {
  //     document.getElementsByClassName("wrapper")[0].style.display =
  //       "flex";
  //   };
  // }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {busy}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div> {img}</div>
        <div
          style={{
            margin:'20px',
            fontWeight: " 500",
            fontSize: "14px",
            lineHeight: " 140%",
            textAlign: "center",
            color: "#2C2C2C",
          }}
        >
         Card successfully attached
        </div>
        <div
          style={{
            margin:'20px',
            fontWeight: " 400",
            fontSize: "14px",
            lineHeight: " 140%",
            textAlign: "center",
            color: "#2C2C2C",
            opacity: "0.5",
          }}
        >
          You can now make payments with the attached card
        </div>
      </div>
      {kor}
    </div>
  );
};
export default SuccessEn;
