import React, {Component} from 'react';
import './Managers.scss';
import {withRouter} from 'react-router-dom';
import {deleteManager, getManagerDetails} from "./index";
import edit from '../../pictures/edit.png';
import trash from '../../pictures/trash.png'
import {Modal} from "@mui/material";
import {Edit, NavigationLink, PageTitle, Row, SubmitButton, Trash} from "../../components/styled";

class Managers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            open: false,
            manager: ''
        };
    }

    componentDidMount() {
        getManagerDetails().then(res => {
            this.setState({user: res.models}, () => console.log('state', this.state.user))
        });
    }

    redirectToEdit = ({id}) => {
        const {history} = this.props;
        if (history) history.push(`/editManager#${id}`);
    }

    render() {
        const {history} = this.props;
        const handleClose = () => {
            this.setState({open: false})
        };
        const closeDeleteManager = ({id}) => {
            console.log(this.state.manager.id);
            deleteManager(this.state.manager.id).then((res) => getManagerDetails().then(res => {
                this.setState({user: res.models}, () => console.log('state', this.state.user))
            }))
            handleClose()
        }

        return (
            history ?
                <>
                    <Modal
                        open={this.state.open}
                        onClose={handleClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <div className={"modalWindow"}>
                            <div className="modalBox">
                                {`Ջնջե՞լ ${this.state.manager.firstName} ${this.state.manager.lastName}-ին`}
                                <div>
                                    <SubmitButton className='addNew' onClick={closeDeleteManager}>Ջնջել</SubmitButton>
                                    <SubmitButton className='addNew cancelButton' onClick={handleClose}>Զեղարկել</SubmitButton>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <PageTitle>Մենեջերներ</PageTitle>
                    <ul>
                        {
                            this.state.user && this.state.user.map((item) => {
                                return (
                                    <Row smallRow>
                                        <span style={{border: 'none'}}>{item.firstName} {item.lastName} </span>
                                        <div>
                                            <span style={{border: 'none'}}>{item.phoneNumber} </span>
                                            <Edit onClick={() => this.redirectToEdit(item)}><img
                                                src={edit}/></Edit>
                                            <Trash onClick={() => this.setState({open: true, manager: item})}>
                                                <img src={trash}/></Trash>
                                        </div>
                                    </Row>
                                )
                            })
                        }
                        <NavigationLink to={"/addManager"} distance={"30px"}>Ստեղծել նոր հաշիվ</NavigationLink>
                    </ul>
                </> : ''
        );
    }
}

export default withRouter(Managers)