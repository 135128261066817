import React, { memo, useEffect, useState } from "react";
import {
    FormBody,
    InfoText,
    InfoWrapper,
    Input,
    Label,
    PageTitle,
    SubmitButton,
    WrapForm,
    Dropdown, Loading, DatePickerInput
} from "../../components/styled";
import { Formik } from "formik";
import { Alert } from "@mui/material";
import moment from "moment"
import CircularProgress from "@mui/material/CircularProgress";

const Finances = memo(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem("userId");
    const [role, setRole] = useState(localStorage.getItem('role'));
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [finances, setFinances] = useState(null);
    const [categories, setCategories] = useState(null);
    const [status, setStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const [networkDropdown, setNetworkDropdown] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [networks, setNetworks] = useState(null);

    useEffect(() => {
        setRole(localStorage.getItem('role'))
    }, [localStorage.getItem('role')])

    useEffect(() => {
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };

            try {
                const response = await fetch(role === "Network" ? `api/Network/GetCategories?networkId=${userId}` : `api/Network/GetCategories`, requestOptions);
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                    return;
                }
                if (response.status === 500) {
                    setStatus("error");
                    setErrorMessage("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։");
                    return;
                }
                const data = await response.json();
                setCategories(data.models);

                const pageDataResponse = await fetch(`api/Network/GetFinancesPageInfo`, requestOptions);
                const pageData = await pageDataResponse.json();
                setNetworks(role === "Network" ? pageData.model : pageData.models);
            } catch (error) {
                console.error("Error fetching data:", error);
                setStatus("error");
                setErrorMessage("An error occurred while fetching data.");
            }
        };

        fetchData();
    }, []);


    const selectCategory = (value, type) => {
        setFinances({
            total: '-',
            onlineTotal: '-',
            cleanTotal: '-',
            cleanOnline: '-',
            supportTotal: '-',
            supportOnline: '-',
            bankCharges: '-',
        });

        if (type === "branch") {
            setSelectedCategory(value)
            setDropdown(false)
        } else {
            setSelectedNetwork(value)
            setNetworkDropdown(false)
        }
    }
    return (
        <>
            {status === "error" ?
                <Alert style={{ marginBottom: "20px" }} severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress style={{ color: "white" }} />
                </Loading>
            }
            <PageTitle>Ֆինանսներ</PageTitle>
            <Formik
                initialValues={{
                    networkId: userId,
                }}
                onSubmit={(async values => {
                    setStatus("loading")
                    if (startDate && endDate) {
                        setDropdown(false)
                        setNetworkDropdown(false)
                        values["firstDate"] = startDate;
                        values["secondDate"] = endDate;
                        selectedCategory?.id && (values["branchId"] = selectedCategory?.id);
                        const requestOptions = {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            mode: 'cors',
                        };
                        let selectedUserId = "";
                        if ((selectedNetwork?.networkId || selectedNetwork?.networkId == 0)) {
                            selectedUserId = selectedNetwork?.networkId
                        } else if (role === "Network") {
                            selectedUserId = userId;
                        }
                        const response = await fetch(role === "Network" ?
                            `api/Network/GetFinances?networkId=${selectedUserId}&firstDate=${startDate}&secondDate=${endDate}${selectedCategory?.id ? `&branchId=${selectedCategory?.id}` : ''}`
                            : `api/Network/GetFinances?networkId=${selectedUserId}&firstDate=${startDate}&secondDate=${endDate}${selectedCategory?.id ? `&branchId=${selectedCategory?.id}` : ''}`, requestOptions)
                        const data = await response.json()
                        if (response.status === 200) {
                            setStatus('success')
                            setFinances(data.model)
                        } else if (response.status === 401) {
                            localStorage.clear();
                            window.location.reload()
                        }
                    } else {
                        setStatus("error")
                        setErrorMessage('Լրացրեք բոլոր դաշտերը')
                    }

                })}
            >
                {props =>
                    <WrapForm onSubmit={props.handleSubmit}>
                        <FormBody alignEnd maxWidth={"1500px"}>
                            <Label>
                                Ընտրեք ժամանակահատված՝
                                <div className={"d-flex align-items-center mt-3"}>
                                    <Label>
                                        <DatePickerInput
                                            onChange={(e, dateString) => {
                                                setStartDate(moment(dateString, "DD.MM.YYYY").format('YYYY-MM-DD'))
                                            }}
                                            format={"DD.MM.YYYY"}
                                            suffixIcon={null}
                                            placeholder={''}
                                        />
                                    </Label>
                                    -
                                    <Label>
                                        <DatePickerInput
                                            format={"DD.MM.YYYY"}
                                            suffixIcon={null}
                                            onChange={(e, dateString) => {
                                                setEndDate(moment(dateString, "DD.MM.YYYY").format('YYYY-MM-DD'))
                                            }}
                                            placeholder={''}
                                        />
                                    </Label>
                                </div>
                            </Label>
                            {role !== "Network" &&
                                <Label open={networkDropdown} dropdown
                                    className={`select ${networkDropdown && "opened"}`}
                                    htmlFor="type">
                                    <span>Ցանց՝</span>
                                    <Input
                                        readOnly={true}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        onClick={() => setNetworkDropdown(!networkDropdown)}
                                        value={selectedNetwork?.name || 'Բոլորը'}
                                        name={"type"}
                                        type="text"
                                    />
                                    {networkDropdown && networks &&
                                        <Dropdown>
                                            <li data-key={0} value={0}
                                                onClick={() => {
                                                    setSelectedNetwork(null);
                                                    setNetworkDropdown(false);
                                                    setSelectedCategory(null)
                                                }}>Բոլորը
                                            </li>
                                            {networks?.map(model =>
                                                <li data-key={0} value={0}
                                                    onClick={() => {
                                                        console.log(model);
                                                        setSelectedNetwork(model);
                                                        setNetworkDropdown(false);
                                                        setSelectedCategory(null)
                                                    }}>{model.name}
                                                </li>
                                            )}
                                        </Dropdown>}
                                </Label>}
                            <Label open={dropdown} dropdown className={`select ${dropdown && "opened"}`} htmlFor="type">
                                <span>Մասնաճյուղ՝</span>
                                <Input
                                    readOnly={true}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    onClick={() => setDropdown(!dropdown)}
                                    value={selectedCategory?.address || 'Բոլորը'}
                                    name={"type"}
                                    type="text"
                                />
                                {dropdown && (selectedNetwork || networks) &&
                                    <Dropdown>
                                        <li data-key={0} value={0}
                                            onClick={(e) => {
                                                setSelectedCategory(null)
                                                setDropdown(false)
                                            }}>Բոլորը
                                        </li>
                                        {selectedNetwork ? selectedNetwork?.branches?.map(model =>
                                            <li data-key={0} value={0}
                                                onClick={() => {
                                                    setSelectedCategory(model)
                                                    setDropdown(false)
                                                }}>{model.address}
                                            </li>
                                        ) : networks?.branches?.map(model =>
                                            <li data-key={0} value={0}
                                                onClick={() => {
                                                    setSelectedCategory(model)
                                                    setDropdown(false)
                                                }}>{model.address}
                                            </li>
                                        )}
                                    </Dropdown>}
                            </Label>
                            <SubmitButton dark width>
                                {status === "loading" ? "Բեռնում..." : "Ցուցադրել"}
                            </SubmitButton>
                        </FormBody>
                    </WrapForm>}
            </Formik>
            <div className={"d-flex w-100 justify-content-between"}>
                <div className={"mt-5"} style={{ width: "23%" }}>
                    <InfoWrapper>
                        <div style={{
                            fontSize: '20px', maxWidth: "80px",
                            fontWeight: 'bolder'
                        }}>Ընդհանուր գումար(դրամ)
                        </div>
                        <InfoText>
                            {finances ? finances?.total.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    <InfoWrapper>
                        Օնլայն է վճարվել՝
                        <InfoText>
                            {finances ? finances?.onlineTotal.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    {/*<InfoWrapper>*/}
                    {/*    Կանխիկ է վճարվել՝*/}
                    {/*    <InfoText>*/}
                    {/*        {finances ? (finances?.cashPaid).toFixed(2) : '-'}*/}
                    {/*    </InfoText>*/}
                    {/*</InfoWrapper>*/}
                </div>

                <div className={"d-flex flex-column justify-content-between mt-5"} style={{ width: "23%" }}>
                    <InfoWrapper>
                        <div style={{ fontSize: '20px', maxWidth: "240px", fontWeight: 'bolder' }}>
                            Բանկային վճարներ(դրամ)
                        </div>
                        <InfoText>
                            {finances ? finances?.bankCharges.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                </div>

                <div className={"d-flex flex-column justify-content-between mt-5"} style={{ width: "23%" }}>
                    <InfoWrapper>
                        <div style={{ fontSize: '20px', maxWidth: "240px", fontWeight: 'bolder' }}>Busy.am սպասարկման
                            վճար(դրամ)
                        </div>
                        <InfoText>
                            {finances ? finances?.supportTotal.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    <InfoWrapper>
                        Օնլայն է վճարվել՝
                        <InfoText>
                            {finances ? finances?.supportOnline.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    {/*<InfoWrapper>*/}
                    {/*    Կանխիկ է վճարվել՝*/}
                    {/*    <InfoText>*/}
                    {/*        {finances ? finances?.supportCash.toFixed(2) : '-'}*/}
                    {/*    </InfoText>*/}
                    {/*</InfoWrapper>*/}
                </div>

                <div className={"d-flex flex-column justify-content-between mt-5"} style={{ width: "23%" }}>
                    <InfoWrapper>
                        <div style={{
                            fontSize: '20px', maxWidth: "220px",
                            fontWeight: 'bolder'
                        }}>Ռեստորանի մաքուր եկամուտ(դրամ)
                        </div>
                        <InfoText>
                            {finances ? finances?.cleanTotal.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    <InfoWrapper>
                        Օնլայն է վճարվել՝
                        <InfoText>
                            {finances ? finances?.cleanOnline.toFixed(2) : '-'}
                        </InfoText>
                    </InfoWrapper>
                    {/*<InfoWrapper>*/}
                    {/*    Կանխիկ է վճարվել՝*/}
                    {/*    <InfoText>*/}
                    {/*        {finances ? (finances?.cashPaid - finances?.supportCash).toFixed(2) : '-'}*/}
                    {/*    </InfoText>*/}
                    {/*</InfoWrapper>*/}
                </div>




                {/*<div className={"d-flex flex-column justify-content-between mt-5"} style={{width: "30%"}}>*/}
                {/*    */}
                {/*   */}
                {/*    /!*<InfoWrapper>*!/*/}
                {/*    /!*    Կանխիկ է վճարվել՝*!/*/}
                {/*    /!*    <InfoText>*!/*/}
                {/*    /!*        {finances ? finances?.supportCash.toFixed(2) : '-'}*!/*/}
                {/*    /!*    </InfoText>*!/*/}
                {/*    /!*</InfoWrapper>*!/*/}
                {/*</div>*/}
            </div>
            {/*<div className={"d-flex w-100 justify-content-between"}>*/}
            {/*    <div className={"d-flex flex-column justify-content-between mt-5"} style={{width: "30%"}}>*/}
            {/*        <InfoWrapper>*/}
            {/*            <div style={{fontSize: '20px', maxWidth: "240px", fontWeight: 'bolder'}}>*/}
            {/*                Բանկային վճարներ(դրամ)*/}
            {/*            </div>*/}
            {/*            <InfoText>*/}
            {/*                {finances ? finances?.bankCharges.toFixed(2) : '-'}*/}
            {/*            </InfoText>*/}
            {/*        </InfoWrapper>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
});

export default Finances;