import styled from "styled-components";

export const NetworkWrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   // justify-content: space-between;
   & > div:nth-child(2) {
     width: 55%;
   }

   & > div:last-child {
     display: flex;
     flex-direction: column;
     //align-items: flex-end;
     width: 45%;
     padding-left: 20px;
     position: sticky;
     top: 0;
     height: 100px;
     margin-bottom: 30px;
   }
   ul.branches {
    max-height: 70vh;
    overflow: auto;
   }
`;
export const List = styled.ul`
  ${props=>props.smallRow && 'max-width: 635px'};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Networks = styled.div`
    width: 55%;
`;

// .branchesWrapper {

//   h2 {
//     margin-top: 53px;
//     margin-bottom: 42px;
//   }
// }
//
// .linkWrapper {
//   display: flex;
//   justify-content: flex-end;
// }
//
// .imageUploadWrapper {
//   display: flex;
//   flex-direction: column;
//
//   p{
//     margin-bottom: 0;
//   }
//
//   span.upload {
//     opacity: 0.5;
//     text-align: center;
//     margin-bottom: 15px;
//     ~span {
//       color: #FF5757;
//       text-align: center;
//     }
//   }
//
//   .imageUpload {
//     height: 373px;
//     width: 373px;
//     background: #F2F2F2;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     border-color: transparent;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 13px;
//     line-height: 15px;
//     text-align: center;
//     color: black;
//     cursor: pointer;
//     margin: 10px 0;
//     overflow: hidden;
//     border-radius: 8px;
//     &.round {
//       width: 150px;
//       height: 150px;
//       border-radius: 50%;
//     }
//     &.small {
//       height: 150px;
//       width: 200px;
//     }
//
//     input {
//       display: none;
//     }
//
//     img {
//       max-width: 100%;
//       max-height: 100%;
//       object-fit: contain;
//       margin-bottom: 0;
//     }
//   }
// }
//
// .branch label span{
//   color: #2C2C2C;
//   opacity: 0.7;
// }
//
// .select:after {
//   content: url("../../pictures/select.svg");
//   position: absolute;
//   bottom: 20%;
//   right: 20px;
// }
//
// .select.opened:after {
//   transform: rotate(180deg);
// }