import React, {Component} from 'react';
import {Sidebar} from "./SideBar/Sidebar";
import './Layout.scss'
import logo from '../pictures/headerLogo.svg'

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.state = {
            role: localStorage.getItem('role'),
            icon: localStorage.getItem('icon')
        }
    }

    componentDidMount() {
        this.setState({role: localStorage.getItem('role')},)
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            mode: 'cors'
        };
        fetch('api/Manager/GetPage', requestOptions)
            .then(function (response) {
                if(response.status === 401){
                    localStorage.clear();
                    window.location.reload()
                }
                // console.log(response.status);
                // console.log(response.json());
            }).catch(error=>{
                if(error.status === 500){
                    alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
                }
            });
    }
    componentWillUnmount(){
        this.setState({role: localStorage.getItem('role')},)
    }

    render() {
        return (
            <div style={{display: "flex"}} className="sidebar_component">
                {<Sidebar logOut={this.props.logOut}/>}
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <div className='headerButton'>
                        <div className='logo'>
                            <img src={this.state.icon || logo}></img>
                            <div className='textsLogo'>
                                <p style={{marginBottom: '0'}}>BusyApp</p>
                                <p>{this.state.role}</p>
                            </div>
                        </div>
                        {/*<button className='Logout' onClick={() => this.props.logOut()}>Log Out</button>*/}
                    </div>
                    <div className="ContainerLayout">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

