import styled from "styled-components";
import { Link } from "react-router-dom";
import checkmark from "../pictures/checked.svg";
import select from "../pictures/select.svg";
import { DatePicker } from 'antd';

export const PageTitle = styled.h1`
    font-size: 36px;
    line-height: 44px;
    font-family: "Montserrat arm", "sans-serif";
    margin-bottom: ${props => props.smallDistance ? '35px' : '57px'};
    width: 100%;
`;

export const Row = styled.li`
    pointer-events: ${props => props.index ? 'all' : 'none'};
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: ${props => props.draggable ? 'pointer' : 'default'};
    max-width: ${props => props.smallRow ? '635px' : props.largeRow ? '766px' : ''};
    width: 100%;
    background-color: ${props => props.selected ? '#2C2C2C' : !props.disabled ? '#F2F2F2' : 'yellow'};
    border-radius: 8px;
    margin-bottom: 20px;
    color: ${props => props.selected ? '#FFFFFF' : '#2C2C2C'};
    padding: 23px 30px;
    & > div:last-child {
        column-gap: 10px;
        display: flex;
        align-items: center;
        span {
          margin-right: 35px;
        }
  }
`;

export const Edit = styled.button`
   width: 35px;
   height: 35px;
   border: none;
   margin-right: 10px;
   border-radius: 4px;
   background-color: ${props => props.selected ? '#FFFFFF' : '#2C2C2C'};
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const Trash = styled.button`
    border: none;
    background-color: ${props => props.background && ''} 
`;

export const NavigationLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  float: ${props => props.right && 'right'};
  background-color: ${props => props.black ? 'black' : '#FF5757'};
  border-radius: 53px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border: none;
  width: 313px;
  text-decoration: unset;
  cursor: pointer;
  margin-top: ${props => props.distance};
  &:hover {
    color: #FFFFFF;
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-color: ${props => props.dark ? '#2C2C2C' : '#FF5757'};
  border-radius: ${props => props.radius ? '4px' : '53px'};
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  transition: .5s;
  font-size: 16px;
  line-height: 20px;
  border: none;
  width: ${props => props.width || '313px'};
  text-decoration: unset;
  cursor: pointer;
  margin-top: ${props => props.distance};
  &:hover {
    opacity: .8;
    color: #FFFFFF;
  }
`;

export const Label = styled.label`
    max-width: ${props => props.small ? '280px' : props.delete ? '242px' : props.full ? '100%' : props.show ? '150px' : '373px'};
    display: flex;
    flex-direction: ${props => props.banners ? 'row' : 'column'};
    ${props => props.halls && `
        & img {
            height: fit-content;
            align-self: center;
            margin-left: 5px;
            cursor: pointer;
        }
    `};
    ${props => props.banners && `
        align-items: center;
        white-space: nowrap;
        column-gap: 20px;
    `};
    width: ${props => props.threeOnRow ? '30%' : '100%'};
    ${props => props.imageUpload && !props.full && `
        width: 373px;
    `}
    ${props => props.imageUpload && `
        height: 373px;
        background: #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-color: transparent;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: black;
        cursor: pointer;
        margin: 10px 0;
        overflow: hidden;
        border-radius: 8px;
        input {
          display: none;
        }
    
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin-bottom: 0;
        }
        img.image{
          width: 100%;
          height: 100%;
        }
    `};
    ${props => props.bannerUpload && `
        height: 255px;
        width: 504px;
        margin-top: 30px;
    `};
    
    ${props => props.round && `
        width: 150px;
        height: 150px;
        border-radius: 50%;
    `};
    ${props => props.small && `
        // width: 200px;
        height: 150px;
    `};
    ${props => props.ad && `
        width: 243px;
        height: 379px;
    `};
    ${props => props.dropdown && `
    position:relative;
    &:after{
        content: url(${select});
        position: absolute;
        // top: 0;
        right: 20px;
        top: 55%;
    }
    `}
    ${props => props.open && `
    &:after{
        transform: rotate(180deg);
    }
        `};
    input[type="password"] {
        background: #F2F2F2;
        border-radius: 10px;
        padding: 20px;
        outline: none;
        border: none;
        color: #2C2C2C;
        margin-top: ${props => !props.banners && '15px'};
        margin-left: ${props => props.distance && '10px'};
        margin-right: ${props => props.distance && '10px'};
        width: 100%;
    }
`;

export const DatePickerInput = styled(DatePicker)`
    background: #F2F2F2;
    border-radius: 10px;
    padding: 20px;
    outline: none;
    border: none;
    color: #2C2C2C;
    width: 100%;
    &.ant-picker-focused {
        box-shadow: none;
    }
`;

export const Input = styled.input`
    background: #F2F2F2;
    border-radius: 10px;
    padding: 20px;
    outline: none;
    border: none;
    color: #2C2C2C;
    margin-top: ${props => !props.banners && '15px'};
    margin-left: ${props => props.distance && '10px'};
    margin-right: ${props => props.distance && '10px'};
    width: 100%;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
      -moz-appearance: textfield;
`;
export const FixCheck = styled.label`
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  input {
    width: 20px;
    height: 20px;
  }
  span {
    width: max-content;
  }

`
export const Textarea = styled.textarea`
    background: #F2F2F2;
    border-radius: 10px;
    padding: 20px;
    outline: none;
    border: none;
    color: #2C2C2C;
    margin-top: 15px;
    width: 100%;
`;

export const FormHeader = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%
`;

export const FormBody = styled.div`
     margin: ${props => props.margin};
     align-items: ${props => props.alignEnd && 'flex-end'};
     max-width: ${props => props.maxWidth};
     display: flex;
     flex-direction: ${props => (props.noWrap && !props.flex) && 'column'};
     flex-wrap: ${props => !props.noWrap && 'wrap'};
     &:not(.imageUploadWrapper) {
        column-gap: 20px;
        row-gap: 15px;
        align-items: ${props => !props.alignEnd && 'flex-start'};
     }
`;

export const WrapForm = styled.form`    
   margin-top: 20px;
`;

export const Icon = styled.img`
     height: 42px;
     width: 42px;
     margin-right: 10px;
     object-fit: contain;
`;

export const Checkmark = styled.span`
   position: absolute;
   top: 0;
   left: 0;
   height: ${props => props.small ? '26px' : '33px'};
   width: ${props => props.small ? '26px' : '33px'};
   background-color: #eee;
   border-radius: 8px;
   &:after {
       content: "";
       position: absolute;
       display: none;
   }
`;

export const Checkbox = styled.label`
   display: block;
   position: relative;
   padding-left: 45px;
   cursor: pointer;
   line-height: ${props => props.small ? '26px' : '33px'};
   margin-top: ${props => props.small && '10px'}; 
   margin-bottom: 30px; 
   user-select: none;
   input {
       position: absolute;
       opacity: 0;
       cursor: pointer;
   }
   input:checked ~ ${Checkmark} {
       background: #E7E7E7;
   }
   input:checked ~ ${Checkmark}:after {
       display: block;
   }
   ${Checkmark}:after {
       border-radius: 50%;
       background-image: url(${checkmark});
       width: 100%;
       height: 100%;
       background-repeat: no-repeat;
       background-position: center;
   }
`;

export const CheckboxWrapper = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 30px;
`;

export const SubTitle = styled.p`
   font-weight: bold;
   font-size: 18px;
   width: 100%;
   margin-bottom: 5px;
`;

export const Dropdown = styled.ul`
   z-index: 1;
   background: white;
   list-style: none;
   padding: 0;
   border-radius: 5px;
   position: absolute;
   width: 100%;
   top: 105px;
   border: 1px solid #c4c4c4;
   overflow: auto;

   li {
       padding: 10px;
       cursor: pointer;
       &:not(:last-child) {
           border-bottom: 1px solid #c4c4c4;
       }
   }
`

export const InfoWrapper = styled.div`

`;

export const InfoText = styled.div`
    background: #F8F8F8;
    border-radius: 10px;
    padding: 20px;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin: 20px 0 50px;
`

export const Loading = styled.div`
    background: black;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1021;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckButton = styled.button`
    outline: none;
    border: none;
    color: #FF5757;
    text-decoration: underline;
`;