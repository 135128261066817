export const img = (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8102 0.0649209C17.8188 0.572707 13.9969 1.9995 10.6669 4.22512C8.8156 5.46261 5.42938 8.84625 4.19094 10.6962C2.36171 13.429 1.06659 16.4867 0.36917 19.7192C-0.123057 22.0009 -0.123057 27.0365 0.36917 29.3182C1.44335 34.2974 3.57479 38.2022 7.19041 41.8151C10.806 45.4279 14.7139 47.5578 19.6969 48.6311C21.9803 49.123 27.0197 49.123 29.3031 48.6311C34.2861 47.5578 38.194 45.4279 41.8096 41.8151C45.4252 38.2022 47.5567 34.2974 48.6308 29.3182C49.1231 27.0365 49.1231 22.0009 48.6308 19.7192C47.5567 14.74 45.4252 10.8352 41.8096 7.2223C38.2581 3.67356 34.5228 1.60248 29.6874 0.500906C28.0835 0.135569 23.3255 -0.127827 21.8102 0.0649209ZM29.3091 4.51596C33.1753 5.54094 36.3292 7.32769 39.0136 10.0141C41.7122 12.7144 43.6113 16.0511 44.538 19.7192C45.1318 22.0704 45.1318 26.967 44.538 29.3182C42.6526 36.7821 36.7728 42.6575 29.3031 44.5414C26.9502 45.1348 22.0498 45.1348 19.6969 44.5414C12.1953 42.6494 6.49491 36.9472 4.4847 29.3241C3.93887 27.2546 3.80803 23.4046 4.20612 21.1262C5.69509 12.6027 12.3279 5.87307 20.8256 4.2639C22.8998 3.87111 27.3784 4.00415 29.3091 4.51596ZM32.8027 17.4677C31.8819 18.341 29.4424 20.6472 27.3819 22.5924C25.3213 24.5377 23.0938 26.6512 22.4318 27.2889L21.2283 28.4487L17.9168 25.0382C14.6226 21.6451 14.6013 21.6275 13.7742 21.6332C12.6241 21.6413 11.8197 22.4255 11.8197 23.539C11.8197 24.2129 11.9709 24.4907 12.9515 25.6176C13.574 26.3331 15.5037 28.3224 17.2396 30.0381C20.3314 33.0944 20.4123 33.1578 21.2194 33.1578C21.9755 33.1578 22.1563 33.0499 23.4158 31.8462C24.1705 31.1247 27.4683 28.0022 30.7441 24.9071C34.0198 21.812 36.8587 18.992 37.0526 18.6403C37.7544 17.3678 36.7761 15.8796 35.2377 15.8796C34.548 15.8796 34.3213 16.0276 32.8027 17.4677Z"
      fill="#7DD185"
    />
  </svg>
);
