import React, {useEffect, useState} from "react";
import DOMPurify from "dompurify";

// import "./Css/tilda-animation-2.0.min.css"
// import "./Css/tilda-blocks-page32471078.min.css"
// import "./Css/tilda-grid-3.0.min.css"
// //
// import "./Js/jquery-1.10.2.min"
// import "./Js/lazyload-1.3.min"
// import "./Js/jquery-1.10.2.min"
// import "./Js/tilda-animation-2.0.min.js"
// import "./Js/tilda-blocks-page32471078.min"
// import "./Js/tilda-events-1.0.min"
// import "./Js/tilda-polyfill-1.0.min"
// import "./Js/tilda-stat-1.0.min"
// import "./Js/tilda-zero-1.1.min"
// import "./Js/tilda-zero-scale-1.0.min"

import Logo from "../../pictures/Logo.svg"
import CommingSoon from "../../pictures/comming-soon.svg"
import Phone from "../../pictures/phone.png"
import {useHistory} from "react-router";

const Landing = () => {
    useEffect(() => {
        // const fetchHTML = async () => {
        //     const response = await fetch('page32471078.html');
        //     const html = DOMPurify.sanitize(await response.text());
        //     setHtmlContent(html);
        // };
        //
        // fetchHTML();
        // const stateObj = { page: "home" }; // State object (optional)
        //
        window.location.replace('page32471078.html');
        //
        // // Replace the current URL with a modified URL
        // const newURL = window.location.href.replace("page32471078.html", "");
        // window.history.replaceState(stateObj, "", newURL);
        
        // const navigateToHTMLFile = () => {
        //     const fileURL = "/page32471078.html";
        //
        //     // Open the new HTML file in a new window or tab
        //     window.open(fileURL, "_blank");
        // };
        
        // navigateToHTMLFile();
        // history.pushState(stateObj, "page32471078.html", "page32471078.html");
        // history.push('page32471078.html')
        // history.replace('page32471078.html');
    }, []);

    // return htmlContent;
    // return<div>
    //     {htmlContent}  
    // </div>
    return null;
    //return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    // return(
    //     <div className="wrapper">
    //         <div className="content">
    //             <div className="landingLogo">
    //                 <div className="info">
    //                     <img className="busy" src={`${Logo}`}/>
    //                     <img className="coming-soon" src={`${CommingSoon}`}/>
    //                 </div>
    //             </div>
    //             <div className="image">
    //                 <img src={`${Phone}`}/>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default Landing