import styled from "styled-components";

export const RaitingDate = styled.p`
    color: #2C2C2C;
    opacity: 0.5;
    margin-right: 20px;
`;

export const Review = styled.div`
    margin-top: 35px;
    & > div {
        margin-bottom: 17px;
    }
`;