import {useNavigate} from "react-router";

export async function IsLoggedIn() {

    let token = localStorage.getItem('token');
    console.log(token);
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
        },
        mode: 'cors'
    }
    let isLogged = await fetch('api/AdminAuthenticate/IsLoggedIn', requestOptions)
        .then(res => {
            if (res.status === 401) {
                return false;
            } else if (res.status === 200) {
                return true;
            }
        });
    return isLogged;
}

export async function getUserDetails() {
    let token = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // ,'Access-Control-Allow-Origin':'*'
        },
        mode: 'cors'
    };
    let user = await fetch('api/AdminAuthenticate/GetUserDetails', requestOptions)
        .then(function (response) {
                //MAP that object thanks.
            if(response.status === 401){
                localStorage.clear();
                window.location.reload()
            }
                return response.json()
            }
        );
    return user
}

export async function getManagerDetails() {
    let token = localStorage.getItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
            // ,'Access-Control-Allow-Origin':'*'
        },
        mode: 'cors'
    };
    let manager = await fetch('api/Manager/GetPage', requestOptions)
        .then(function (response) {
                //MAP that object thanks.
            if(response.status === 401){
                localStorage.clear();
                window.location.reload()
            }
                console.log('5', response.body)
                return response.json()
            }
        );
    return manager
}

export async function deleteManager(id) {
    let token = localStorage.getItem('token');
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
        },
        mode: 'cors'
    };
    await fetch(`api/AdminAuthenticate/DeleteManager?id=${id}`, requestOptions)
}


export function logOut() {
    // let navigate = useNavigate();
    localStorage.removeItem('token');
    // navigate('/login',{replace :true});
}
