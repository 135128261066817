import React, {useEffect, useState} from "react";
import {Field, Formik} from "formik";
import imageUpload from "../../pictures/imageUpload.svg";
import Parameter from "../../components/Parameter";
import {
    Checkbox, Checkmark,
    Dropdown,
    FormBody,
    FormHeader,
    Input,
    Label, Loading,
    PageTitle,
    SubmitButton,
    Textarea,
    WrapForm
} from "../../components/styled";
import {useHistory} from "react-router";
import {Alert} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const AddNewItem = ({edit}) => {
    let token = localStorage.getItem('token');
    const [id, setId] = useState(window.location.hash.slice(1))
    const [item, setItem] = useState()
    const [parameterType, setParameterType] = useState()
    const [type, setType] = useState(false)
    const [selected, setSelected] = useState(item?.item)
    const [image, setImage] = useState()
    const history = useHistory()
    const [discounted, setDiscounted] = useState(item?.discountedPrice)
    const userId = localStorage.getItem("userId")
    const [errorMessage, setErrorMessage] = useState()
    const [status, setStatus] = useState()
    const selectType = (value) => {
        setSelected(value)
        setType(false)
    }
console.log(parameterType);
    useEffect(async () => {
        if (edit) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };
            const response = await fetch(`api/Item/GetItem?id=${id}`, requestOptions)
            const data = await response.json()
            if (response.status === 401) {
                localStorage.clear();
                window.location.reload()
            }
            setItem(data.model)
            selectType(data.model.category)
            setDiscounted(data?.model?.discountedPrice)
        }
    }, [])

    useEffect(async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
        };
        const response = await fetch(`/api/Network/GetCategories?networkId=${userId}`, requestOptions)
        const data = await response.json()
        if (response.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        setParameterType(data.models);
    }, [])

    const previewFile = () => {
        let preview = document.getElementById('uploadImage');
        let file = document.getElementById('image').files[0];
        let reader = new FileReader();
        reader.onloadend = function (e) {
            preview.src = reader.result;
            setImage(reader.result.split(',')[1]);
        };


        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = '';
        }
    }

    return (
        <>
            {status === "error" ?
                <Alert severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress color="inherit"/>
                </Loading>
            }
            <Formik
                enableReinitialize
                initialValues={{
                    "networkId": userId,
                    "nameEn": item?.nameEn,
                    "nameHy": item?.nameHy || null,
                    "nameRu": item?.nameRu || null,
                    "ingredientsEn": item?.ingredientsEn || null,
                    "ingredientsRu": item?.ingredientsRu || null,
                    "ingredientsHy": item?.ingredientsHy || null,
                    "price": item?.price || null,
                    "discountedPrice": item?.discountedPrice || '0',
                }}
                onSubmit={(values) => {
                    setStatus("loading")
                    let newValues = values
                    !discounted && delete values["discountedPrice"]
                    if (edit) {
                        values["category"] = selected?.id || selected || 0
                        image && (values["image"] = image);
                        newValues["id"] = item?.id
                    } else {
                        newValues = values
                        values["image"] = image;
                        newValues["category"] = selected?.id || selected || 0
                    }
                    const requestOptions = {
                        method: edit ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        mode: 'cors',
                        body: JSON.stringify(newValues)
                    };
                    const url = edit ? `api/Item/ChangeItem` : 'api/Item/CreateItem';
                    fetch(url, requestOptions)
                        .then(function (response) {
                                if (response.status === 200) {
                                    history.push('/items')
                                    setStatus('success')
                                } else {
                                    setStatus('error')
                                }
                            }
                        ).catch(response => console.log('err', response));
                }}>
                {props => (
                    props?.values &&
                    <WrapForm className={"wrap column itemsForm"} onSubmit={props.handleSubmit}>
                        <FormHeader className={"titleWrapper d-flex"}>
                            <PageTitle>{edit ? 'Փոփոխել ապրանքը' : 'Ավելացնել նոր ապրանք'}</PageTitle>
                            <SubmitButton className='addNew'
                                          type={"submit"}>{edit ? "Հաստատել" : "Ավելացնել"}</SubmitButton>
                        </FormHeader>
                        <div className={"d-flex w-100 gap-3"}>
                            <div className={"ml-n1"}>
                                <span>Ապրանքի նկար</span>
                                <Label imageUpload>
                                    <Field
                                        name={'image'}
                                        id={'image'}
                                        type={'file'}
                                        onChange={() => {
                                            previewFile();
                                        }}
                                        value={""}
                                    />
                                    <img src={edit ? item?.image : imageUpload}
                                         className={`${edit && 'image'}`} id="uploadImage"
                                         alt="upload"/>
                                </Label>
                            </div>
                            <FormBody>
                                <p className={"subTitle w-100"}>Ընդհանուր ինֆորմացիա</p>
                                <Label threeOnRow htmlFor="nameHy">
                                    <span>Անվանում հայերեն</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.nameHy}
                                        name={"nameHy"}
                                        placeholder={"Ապրանքի անվանում հայերեն"}
                                        type="text"/>
                                </Label>
                                <Label threeOnRow htmlFor="nameRu">
                                    <span>Անվանում ռուսերեն</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.nameRu}
                                        name={"nameRu"}
                                        placeholder={"Ապրանքի անվանում ռուսերեն"}
                                        type="text"/>
                                </Label>
                                <Label threeOnRow htmlFor="name">
                                    <span>Անվանում անգլերեն</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.nameEn}
                                        name={"nameEn"}
                                        placeholder={"Ապրանքի անվանում անգլերեն"}
                                        type="text"/>
                                </Label>
                                <Label threeOnRow htmlFor="ingredientsHy">
                                    <span>Բաղադրությունը հայերեն</span>
                                    <Textarea
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.ingredientsHy}
                                        name={"ingredientsHy"}
                                        placeholder={"Բաղադրությունը հայերեն"}/>
                                </Label>
                                <Label threeOnRow htmlFor="ingredientsRu">
                                    <span>Բաղադրությունը ռուսերեն</span>
                                    <Textarea
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.ingredientsRu}
                                        name={"ingredientsRu"}
                                        placeholder={"Բաղադրությունը ռուսերեն"}/>
                                </Label>
                                <Label threeOnRow htmlFor="ingredients">
                                    <span>Բաղադրությունը անգլերեն</span>
                                    <Textarea
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.ingredientsEn}
                                        name={"ingredientsEn"}
                                        placeholder={"Բաղադրությունը անգլերեն"}/>
                                </Label>
                                <Label threeOnRow dropdown className={`select ${type && "opened"}`} htmlFor="type">
                                    <span>Կատեգորիա</span>
                                    <Input
                                        readOnly={true}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        onClick={() => setType(!type)}
                                        value={selected?.categoryHy || parameterType?.filter(i=>i.id===item?.category)[0]?.categoryHy}
                                        name={"type"}
                                        type="text"
                                    />
                                    {type && parameterType &&
                                    <Dropdown>
                                        {parameterType?.map(param =>
                                            <li data-key={0} value={0}
                                                onClick={() => selectType(param)}>{param?.categoryHy}
                                            </li>
                                        )}
                                    </Dropdown>}
                                </Label>
                                <Label threeOnRow htmlFor="price">
                                    <span>Գին</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={edit && props?.values?.price}
                                        name={"price"}
                                        placeholder={"Գրեք գինը"}
                                        type="text"/>
                                    <Checkbox small onClick={(e) => {
                                        e.preventDefault()
                                        setDiscounted(!discounted)
                                    }}>
                                        <Field checked={discounted}
                                               type="checkbox" name={"flags"}
                                               value={''}/>
                                        <Checkmark small/>Զեղչված</Checkbox>
                                </Label>
                                <Label threeOnRow htmlFor="discountedPrice">
                                    {discounted ? <>
                                        <span>Զեղչված գին</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={edit && props?.values?.discountedPrice}
                                            name={"discountedPrice"}
                                            placeholder={"Գրեք գինը"}
                                            type="text"/>
                                    </> : null}
                                </Label>
                            </FormBody>
                        </div>
                    </WrapForm>
                )}
            </Formik>
        </>
    )
};

export default AddNewItem