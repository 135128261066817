export const busy = (
  <svg
    width="133"
    height="43"
    viewBox="0 0 133 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5619 9.11329L55.5118 23.2817C55.4511 23.7677 55.527 24.1018 55.7396 24.284C55.9522 24.4359 56.2256 24.5118 56.5596 24.5118C57.2886 24.5118 58.0023 24.3447 58.7009 24.0106C59.3994 23.6766 60.3106 23.0843 61.4343 22.2339L63.0744 26.1519C61.0699 28.369 59.1716 29.9939 57.3797 31.0265C55.5878 32.0288 53.7806 32.5299 51.9583 32.5299C49.5893 32.5299 47.8581 31.968 46.7648 30.8443C45.7017 29.6902 45.3221 28.0653 45.6258 25.9696L47.9492 9.11329H57.5619ZM72.0492 9.11329L68.9058 31.8921H61.4343L61.161 25.7874L60.2954 25.2407L62.5733 9.11329H72.0492Z"
      fill="#F34439"
    />
    <path
      d="M85.4104 8.47549C86.6253 8.47549 87.9465 8.52104 89.374 8.61216C90.8014 8.70327 92.1833 8.82476 93.5197 8.97662C94.8864 9.12848 96.0102 9.31071 96.891 9.52331L96.1165 15.0358C94.6283 15.0358 93.1401 15.0358 91.6518 15.0358C90.1636 15.0358 88.7513 15.0358 87.415 15.0358C86.352 15.0358 85.5319 15.051 84.9549 15.0813C84.4082 15.0813 84.0285 15.1117 83.8159 15.1725C83.6033 15.2332 83.4818 15.3395 83.4515 15.4914C83.4211 15.704 83.5426 15.9014 83.8159 16.0836C84.1196 16.2658 84.7423 16.4784 85.6838 16.7214L90.6496 18.0426C91.8948 18.3767 92.9275 18.8626 93.7475 19.5004C94.5675 20.1382 95.1598 20.9431 95.5242 21.915C95.8887 22.8565 95.995 23.9651 95.8431 25.2407C95.6002 27.2452 94.9927 28.7638 94.0208 29.7965C93.0489 30.7987 91.743 31.4821 90.1029 31.8466C88.4932 32.211 86.5494 32.3933 84.2715 32.3933C83.33 32.3933 82.2518 32.3629 81.0369 32.3021C79.8524 32.2718 78.5464 32.1807 77.119 32.0288C75.7219 31.8769 74.2185 31.6643 72.6087 31.391L73.3832 25.8785C73.7781 25.8785 74.4007 25.8785 75.2511 25.8785C76.1015 25.8785 77.043 25.8785 78.0757 25.8785C79.1083 25.8785 80.0954 25.8785 81.0369 25.8785C82.6162 25.8785 83.8007 25.8633 84.5904 25.8329C85.3801 25.7722 85.9116 25.6963 86.1849 25.6052C86.4886 25.4837 86.6557 25.3318 86.6861 25.1496C86.7164 24.9977 86.6709 24.8762 86.5494 24.7851C86.4279 24.6636 86.1849 24.5422 85.8205 24.4207C85.456 24.2688 84.9245 24.1018 84.2259 23.9195L79.4424 22.5528C78.0149 22.1276 76.8912 21.5505 76.0711 20.8216C75.2815 20.0623 74.75 19.1663 74.4766 18.1337C74.2336 17.1011 74.2033 15.9773 74.3855 14.7624C74.5981 13.4261 75.0537 12.3023 75.7522 11.3912C76.4508 10.4497 77.5593 9.73591 79.0779 9.24997C80.6269 8.73365 82.7377 8.47549 85.4104 8.47549Z"
      fill="#F34439"
    />
    <path
      d="M126.182 9.11329C125.969 10.7837 125.605 12.5453 125.089 14.398C124.603 16.2203 124.041 18.0274 123.403 19.8193C122.765 21.6113 122.112 23.2969 121.444 24.8762C120.806 26.4252 120.199 27.7919 119.622 28.9764C119.075 30.1306 118.619 30.981 118.255 31.5277C116.828 33.7752 115.218 35.643 113.426 37.1312C111.664 38.6498 109.721 39.7432 107.595 40.4114C105.469 41.11 103.115 41.3985 100.533 41.277L99.9408 36.129C101.034 35.7341 102.052 35.3849 102.993 35.0812C103.965 34.7774 104.876 34.3674 105.727 33.8511C106.607 33.3652 107.458 32.6666 108.278 31.7554C109.098 30.8139 109.918 29.5231 110.738 27.883C111.619 26.1519 112.363 24.3751 112.97 22.5528C113.608 20.7001 114.155 18.6804 114.61 16.4936C115.096 14.3069 115.537 11.8468 115.932 9.11329H126.182ZM109.371 9.11329L109.736 25.4229C109.736 25.9089 109.979 26.1519 110.465 26.1519H113.016L111.148 31.8921H104.542C103.783 31.8921 103.145 31.6643 102.629 31.2088C102.112 30.7228 101.809 30.1457 101.718 29.4776L98.893 9.11329H109.371Z"
      fill="#F34439"
    />
    <path
      d="M36.4168 8.47373C38.2998 8.47373 39.788 8.91411 40.8814 9.7949C42.0052 10.6757 42.7341 12.012 43.0682 13.804C43.4327 15.5959 43.4023 17.8586 42.9771 20.5921C42.7645 22.0803 42.4 23.5381 41.8837 24.9656C41.3977 26.3627 40.6688 27.6231 39.6969 28.7469C38.725 29.8707 37.419 30.7666 35.779 31.4348C34.1693 32.103 32.1192 32.437 29.6287 32.437C28.7783 32.437 27.7456 32.3915 26.5308 32.3004C25.3463 32.2396 24.1162 32.1334 22.8406 31.9815C21.565 31.8296 20.3805 31.6322 19.2871 31.3892C18.1937 31.1463 17.3281 30.8577 16.6903 30.5236L17.1978 26.9068H4.39413C3.60785 26.9068 2.97045 26.2694 2.97045 25.4832C2.97045 24.6969 3.60785 24.0595 4.39413 24.0595H17.5972L18.3962 18.3648H9.61427C8.82799 18.3648 8.1906 17.7274 8.1906 16.9411C8.1906 16.1548 8.82799 15.5174 9.61427 15.5174H18.7957L19.5946 9.82271H2.02133C1.23505 9.82271 0.597656 9.18531 0.597656 8.39903C0.597656 7.61275 1.23505 6.97536 2.02133 6.97536H19.9941L20.5732 2.84787H9.13971C8.35344 2.84787 7.71604 2.21048 7.71604 1.4242C7.71604 0.63792 8.35344 0.000521366 9.13971 0.000521366H20.9727L20.9727 0H30.5854L29.8565 5.60358C29.735 6.72736 29.5376 7.83593 29.2642 8.92929C29.0547 9.84608 28.7435 10.808 28.3306 11.8151C28.872 11.3054 29.4414 10.8446 30.0387 10.4327C30.9499 9.82526 31.9369 9.35449 33 9.02041C34.063 8.65595 35.2019 8.47373 36.4168 8.47373Z"
      fill="#F34439"
    />
    <path
      d="M6.22097 16.944C6.22097 17.7221 5.5902 18.3529 4.8121 18.3529C4.03401 18.3529 3.40323 17.7221 3.40323 16.944C3.40323 16.1659 4.03401 15.5351 4.8121 15.5351C5.5902 15.5351 6.22097 16.1659 6.22097 16.944Z"
      fill="#F34439"
    />
    <path
      d="M35.1062 15.403L30.9911 19.518C30.6469 19.3479 30.2598 19.2517 29.8507 19.2517C29.4414 19.2517 29.0544 19.3479 28.7103 19.518L26.8686 17.6763C26.544 17.3519 26.0181 17.3519 25.6935 17.6763C25.3691 18.0008 25.3691 18.5268 25.6935 18.8513L27.5352 20.693C27.365 21.0371 27.2689 21.4242 27.2689 21.8334C27.2689 23.2569 28.427 24.4151 29.8507 24.4151C31.2743 24.4151 32.4324 23.2569 32.4324 21.8334C32.4324 21.4242 32.3363 21.0371 32.1661 20.693L36.1604 16.6987L36.2811 16.578C36.6056 16.2535 36.6056 15.7274 36.2811 15.403C35.9567 15.0786 35.4308 15.0786 35.1062 15.403ZM29.8507 22.7535C29.3434 22.7535 28.9306 22.3408 28.9306 21.8335C28.9306 21.3261 29.3434 20.9134 29.8507 20.9134C30.358 20.9134 30.7707 21.3261 30.7707 21.8335C30.7707 22.3408 30.358 22.7535 29.8507 22.7535Z"
      fill="white"
    />
    <path
      d="M21.1907 37.467V38.7963C21.1907 39.5052 21.0135 39.8154 20.4691 39.8154C19.9121 39.8154 19.7285 39.3976 19.7285 38.7963V37.467H17.9815V36.4162H17.1333V41.2079H17.9815V38.2139H18.8867V38.7963C18.8867 39.9546 19.4437 40.5813 20.4691 40.5813C21.4945 40.5813 22.0389 39.9546 22.0389 38.7963V37.467H21.1907Z"
      fill="#F34439"
    />
    <path
      d="M25.3622 37.8215V39.4925C25.3622 40.0369 25.0457 40.4547 24.5076 40.461C24.0582 40.461 23.786 40.1762 23.786 39.7014V36.4162H22.02V37.0176H22.9568V39.942C22.9568 40.7395 23.4252 41.2396 24.1848 41.2396C24.7229 41.2396 25.128 41.0307 25.3622 40.5813V41.2079H26.1914V37.8215H25.3622Z"
      fill="#F34439"
    />
    <path
      d="M31.5009 37.8215V39.6888C31.4439 40.1382 31.1717 40.4547 30.7476 40.4673C30.3298 40.4673 30.1526 40.1825 30.1526 39.7014V37.8215H29.3297V39.4925C29.3297 40.0432 29.0449 40.4547 28.5702 40.4673C28.1714 40.4673 27.9751 40.1825 27.9751 39.7014V37.8215H27.1523V39.942C27.1523 40.7395 27.5574 41.2396 28.2473 41.2396C28.7917 41.2396 29.1905 40.9864 29.393 40.4293C29.5323 40.9357 29.9057 41.2396 30.4501 41.2396C30.9248 41.2396 31.2856 41.0433 31.5009 40.6256V41.2079H32.3237V37.8215H31.5009Z"
      fill="#F34439"
    />
    <path
      d="M33.2145 42.0055C34.2463 41.5561 33.3791 40.6889 33.3791 39.6001V37.8215H34.2083V39.6698C34.2336 40.6952 35.3856 42.246 33.2145 42.5815V42.0055Z"
      fill="#F34439"
    />
    <path
      d="M37.4444 37.7898C36.8937 37.7961 36.4696 38.005 36.229 38.4544V36.4162H35.3998V42.5435H36.229V39.4102C36.2797 38.9228 36.6025 38.5747 37.1152 38.5684C37.5836 38.5684 37.8685 38.8596 37.8685 39.3343V41.2079H38.6977V39.0938C38.6977 38.2899 38.2229 37.7898 37.4444 37.7898Z"
      fill="#F34439"
    />
    <path
      d="M38.6336 35.8022L37.9183 37.1885H38.5766L39.5704 35.8022H38.6336Z"
      fill="#F34439"
    />
    <path
      d="M42.1485 37.7898C41.5978 37.7961 41.1737 38.005 40.9331 38.4544V37.8215H40.1039V42.5435H40.9331V39.4102C40.9838 38.9228 41.3066 38.5747 41.8193 38.5684C42.2877 38.5684 42.5726 38.8596 42.5726 39.3343V41.2079H43.4018V39.0938C43.4018 38.2899 42.927 37.7898 42.1485 37.7898Z"
      fill="#F34439"
    />
    <path
      d="M50.5398 37.8215V39.6888C50.4828 40.1382 50.2106 40.4547 49.7865 40.4673C49.3688 40.4673 49.1915 40.1825 49.1915 39.7014V37.8215H48.3687V39.4925C48.3687 40.0432 48.0838 40.4547 47.6091 40.4673C47.2103 40.4673 47.0141 40.1825 47.0141 39.7014V37.8215H46.1912V39.942C46.1912 40.7395 46.5963 41.2396 47.2863 41.2396C47.8306 41.2396 48.2294 40.9864 48.4319 40.4293C48.5712 40.9357 48.9447 41.2396 49.489 41.2396C49.9638 41.2396 50.3246 41.0433 50.5398 40.6256V41.2079H51.3627V37.8215H50.5398Z"
      fill="#F34439"
    />
    <path
      d="M59.9505 38.4291V37.8278H57.3553V36.4162C56.7286 36.5238 56.0323 36.5238 55.5133 36.4162C54.2537 36.0808 53.4498 36.929 53.9435 37.5999C54.3992 38.1126 54.7347 38.5747 54.7347 39.2014V39.4735C54.7347 40.0242 54.4182 40.4357 53.8802 40.4483C53.4308 40.461 53.1523 40.1635 53.1523 39.6888V37.8088H52.3294V39.923C52.3294 40.7268 52.7978 41.2269 53.5574 41.2269C54.0954 41.2269 54.5005 41.018 54.7347 40.5686V41.1953H55.5639V39.2267C55.5639 38.657 55.4563 38.2076 55.0322 37.8151C54.3613 37.1948 54.6777 36.796 55.507 37.0872C55.7475 37.1695 56.1273 37.2581 56.5324 37.2075V39.942C56.5324 40.7395 56.9945 41.2396 57.7604 41.2396C58.2921 41.2396 58.7035 41.0307 58.9377 40.5749V41.2079H59.7669V39.0684H58.9377V39.4925C58.9377 40.0369 58.6212 40.4547 58.0832 40.461C57.6338 40.461 57.3553 40.1762 57.3553 39.7014V38.4291H59.9505Z"
      fill="#F34439"
    />
    <path
      d="M63.1495 37.8215V39.4925C63.1495 40.0369 62.833 40.4547 62.2949 40.461C61.8455 40.461 61.5733 40.1762 61.5733 39.7014V36.4162H59.8073V37.0176H60.7441V39.942C60.7441 40.7395 61.2126 41.2396 61.9721 41.2396C62.5102 41.2396 62.9153 41.0307 63.1495 40.5813V41.2079H63.9787V37.8215H63.1495Z"
      fill="#F34439"
    />
    <path
      d="M69.2882 37.8215V39.6888C69.2312 40.1382 68.959 40.4547 68.5349 40.4673C68.1172 40.4673 67.9399 40.1825 67.9399 39.7014V37.8215H67.117V39.4925C67.117 40.0432 66.8322 40.4547 66.3575 40.4673C65.9587 40.4673 65.7625 40.1825 65.7625 39.7014V37.8215H64.9396V39.942C64.9396 40.7395 65.3447 41.2396 66.0346 41.2396C66.579 41.2396 66.9778 40.9864 67.1803 40.4293C67.3196 40.9357 67.6931 41.2396 68.2374 41.2396C68.7122 41.2396 69.073 41.0433 69.2882 40.6256V41.2079H70.111V37.8215H69.2882Z"
      fill="#F34439"
    />
    <path
      d="M75.7492 39.0684H75.1289C75.0972 38.2266 74.3503 37.7772 73.5654 37.7772C72.9704 37.7772 72.3437 38.0114 71.9703 38.4924V37.8215H71.1411V42.5435H71.9703V39.5432C71.9703 39.0115 72.6666 38.4988 73.3059 38.4988C73.7616 38.4988 74.192 38.695 74.306 39.0684H73.8186C73.059 39.0684 72.5779 39.4862 72.5779 40.1382C72.5779 40.8534 73.1413 41.2649 73.8692 41.2649C74.5149 41.2649 75.1289 40.8661 75.1289 40.0812V39.6571H75.7492V39.0684ZM74.3313 40.1572C74.3313 40.3914 74.2427 40.6572 73.8313 40.6572C73.5527 40.6572 73.2742 40.4863 73.2742 40.1508C73.2742 39.809 73.5401 39.6571 73.768 39.6571H74.3313V40.1572Z"
      fill="#F34439"
    />
    <path
      d="M80.4704 37.8215V39.6888C80.4135 40.1382 80.1413 40.4547 79.7172 40.4673C79.2994 40.4673 79.1222 40.1825 79.1222 39.7014V37.8215H78.2993V39.4925C78.2993 40.0432 78.0145 40.4547 77.5397 40.4673C77.141 40.4673 76.9447 40.1825 76.9447 39.7014V37.8215H76.1219V39.942C76.1219 40.7395 76.527 41.2396 77.2169 41.2396C77.7613 41.2396 78.1601 40.9864 78.3626 40.4293C78.5019 40.9357 78.8753 41.2396 79.4197 41.2396C79.8944 41.2396 80.2552 41.0433 80.4704 40.6256V41.2079H81.2933V37.8215H80.4704Z"
      fill="#F34439"
    />
    <path
      d="M84.8464 37.8215V39.4925C84.8464 40.0369 84.5299 40.4547 83.9919 40.461C83.5425 40.461 83.2703 40.1762 83.2703 39.7014V36.4162H81.5043V37.0176H82.4411V39.942C82.4411 40.7395 82.9095 41.2396 83.6691 41.2396C84.2071 41.2396 84.6122 41.0307 84.8464 40.5813V41.2079H85.6756V37.8215H84.8464Z"
      fill="#F34439"
    />
    <path
      d="M89.0419 37.8215V39.4925C89.0419 40.0369 88.7254 40.4547 88.1873 40.461C87.7379 40.461 87.4594 40.1762 87.4594 39.7014V36.4162H86.6365V39.942C86.6365 40.7395 87.1049 41.2396 87.8645 41.2396C88.4026 41.2396 88.8077 41.0307 89.0419 40.5813V42.5435H89.8711V37.8215H89.0419Z"
      fill="#F34439"
    />
    <path
      d="M91.7262 41.9422V39.4102C91.7769 38.9228 92.0997 38.5747 92.6124 38.5684C93.0808 38.5684 93.3657 38.8596 93.3657 39.3343V41.1826H94.1949V39.0938C94.1949 38.2899 93.7201 37.7898 92.9416 37.7898C92.3909 37.7961 91.9668 38.005 91.7262 38.4544V37.8215H90.897V42.5435H94.3215V41.9422H91.7262Z"
      fill="#F34439"
    />
    <path
      d="M118.439 39.5911C118.63 39.5911 118.775 39.6433 118.873 39.7476C118.978 39.8457 119.018 39.996 118.993 40.1985L118.929 40.6126C118.91 40.7966 118.849 40.9347 118.744 41.0267C118.645 41.1126 118.494 41.1555 118.291 41.1555H117.949C117.524 41.1555 117.336 40.9531 117.385 40.5482L117.441 40.1341C117.496 39.7721 117.712 39.5911 118.088 39.5911H118.439Z"
      fill="#F34439"
    />
    <path
      d="M122.737 36.4531C122.897 36.4531 123.082 36.4623 123.291 36.4807C123.507 36.4991 123.723 36.5237 123.938 36.5544C124.154 36.5789 124.351 36.6065 124.53 36.6372C124.715 36.6678 124.857 36.6985 124.955 36.7292L123.717 37.5942C123.519 37.5881 123.335 37.585 123.162 37.585C122.99 37.5789 122.805 37.5758 122.608 37.5758C122.46 37.5758 122.33 37.5911 122.219 37.6218C122.108 37.6464 122.013 37.6985 121.933 37.7783C121.859 37.8519 121.791 37.9654 121.73 38.1187C121.674 38.2721 121.628 38.4746 121.591 38.7261C121.542 39.0697 121.511 39.3335 121.498 39.5175C121.492 39.6954 121.517 39.815 121.572 39.8764C121.628 39.9377 121.723 39.9684 121.859 39.9684C122.001 39.9561 122.152 39.8979 122.312 39.7936C122.472 39.6831 122.626 39.539 122.774 39.3611C122.928 39.1831 123.067 38.9868 123.19 38.7721L123.467 39.2598C123.362 39.6647 123.202 40.0144 122.986 40.3089C122.771 40.6034 122.512 40.8304 122.21 40.9899C121.908 41.1494 121.566 41.2414 121.184 41.2659C120.759 41.2905 120.439 41.2077 120.223 41.0175C120.007 40.8273 119.875 40.542 119.826 40.1617C119.782 39.7752 119.801 39.3058 119.881 38.7537C119.961 38.22 120.081 37.7997 120.241 37.493C120.402 37.1801 120.602 36.9501 120.842 36.8028C121.083 36.6556 121.36 36.5605 121.674 36.5175C121.994 36.4746 122.349 36.4531 122.737 36.4531ZM123.31 36.6004L124.955 36.7292L124.327 41.1555H123.033V40.1433L122.82 40.0052L123.31 36.6004Z"
      fill="#F34439"
    />
    <path
      d="M127.215 36.582L127.261 37.5666L127.418 37.6862L126.938 41.1555H125.265L125.912 36.582H127.215ZM128.99 36.4531C129.427 36.4531 129.748 36.5697 129.951 36.8028C130.16 37.0298 130.234 37.3488 130.173 37.7599L129.692 41.1555H128.084L128.491 38.2292C128.515 38.0881 128.506 37.993 128.463 37.9439C128.42 37.8948 128.343 37.8703 128.232 37.8703C128.145 37.8703 128.05 37.8887 127.945 37.9255C127.847 37.9562 127.73 38.0145 127.594 38.1003C127.465 38.1862 127.307 38.3028 127.123 38.45L126.864 37.7138C127.252 37.2721 127.609 36.9531 127.936 36.7568C128.263 36.5544 128.614 36.4531 128.99 36.4531ZM131.781 36.4531C132.231 36.4531 132.557 36.5697 132.761 36.8028C132.964 37.0359 133.038 37.355 132.982 37.7599L132.502 41.1555H130.838L131.245 38.2292C131.263 38.0819 131.248 37.9868 131.199 37.9439C131.149 37.8948 131.078 37.8703 130.986 37.8703C130.906 37.8703 130.817 37.8887 130.718 37.9255C130.619 37.9562 130.505 38.0145 130.376 38.1003C130.247 38.1801 130.096 38.2967 129.923 38.45L129.655 37.7138C130.043 37.2721 130.401 36.9531 130.727 36.7568C131.054 36.5544 131.405 36.4531 131.781 36.4531Z"
      fill="#F34439"
    />
  </svg>
);
