import React, {useEffect, useState} from "react";
import {
    FormBody,
    Input,
    Label,
    PageTitle,
    SubmitButton,
    WrapForm,
    Dropdown
} from "../../components/styled";
import {Formik} from "formik";
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import selectedStar from "../../pictures/selectedStar.svg";
import disabledStar from "../../pictures/disablesStar.svg";
import { RaitingDate, Review } from "./styled";

const Rating = () => {
    const [branchesDropdown, setBranchesDropdown] = useState(false)
    const [models, setModels] = useState()
    const [selected, setSelected] = useState()
    const [raitings, setRaitings] = useState()
    let token = localStorage.getItem('token');
    const userId = localStorage.getItem("userId")
    const countArray = [10, 30, 50, 100]
    const [count, setCount] = useState()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState()
    const [ststua, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [countDropdown, setCountDropdown] = useState(false)

    useEffect(async () => {
        console.log('asdasd');
        const modelsRequestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const getModels = await fetch(`api/Network/GetModelsForDropDown`, modelsRequestOptions)
        if(getModels.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        if (getModels.status === 500) {
            alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
        }
        const modelsData = await getModels.json();
        getModels.status === 200 && setModels(modelsData.models.find(item=>item?.networkId == userId).branches)
    }, [])

    const selectType = (value) => {
        console.log('value',value)
        setSelected(value)
        setBranchesDropdown(false)
    }
    const getRaitings = async() => {
        setCountDropdown(false)
        setBranchesDropdown(false)
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        const data = await fetch(`api/Rating/GetRatingsForBranch?branchId=${selected[0]}&number=${count}&pageNumber=${page}`, requestOptions);
        if(data.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        const raitingData = await data.json();
        if(data.status === 200){
            setRaitings(raitingData?.model?.ratings)
            setStatus("success")
        } else if (data.status === 500) {
            setStatus("error")
            setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
        } else{
            setStatus("error")
            setErrorMessage()
        }
        setTotal(raitingData?.model?.pageCount)
    }
    useEffect(()=>{
        (count && page && selected) && getRaitings()
    },[page])
    return (
        <>
            <PageTitle>Վարկանիշ</PageTitle>
            <Formik
            initialValues={{
            }}
            onSubmit={(async (values) => {
                setStatus("loading")
                getRaitings()
            })}
            >
                {props =>
                    <WrapForm onSubmit={props.handleSubmit}>
                        <FormBody alignEnd style={{marginBottom: '20px'}} maxWidth={"1500px"}>
                            <Label>
                                Բոլոր կարծիքները ցանցի կամ մասնաճյուղի մասին՝
                                <div className={"d-flex align-items-center"}>
                                    <Label dropdown className={`select ${false && "opened"}`} style={{marginRight: '10px'}} htmlFor="type">
                                        <span>Մասնաճյուղ՝</span>
                                        <Input
                                            readOnly={true}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onClick={() => {
                                                console.log('ok')
                                                setBranchesDropdown(!branchesDropdown)
                                            }}
                                            value={selected && selected[1]}
                                            name={"branch"}
                                            type="text"
                                        />
                                        {console.log('asdasd',branchesDropdown , models)}
                                        {(branchesDropdown && models) &&
                                        <Dropdown>
                                            {Object.entries(models)?.map(model =>
                                                <li data-key={model[0]} value={model[0]}
                                                    onClick={(e) => selectType(model)}>{model[1]}
                                                </li>
                                            )}
                                        </Dropdown>}
                                    </Label>
                                    <Label dropdown className={`select ${false && "opened"}`} htmlFor="type">
                                        <span>Ցույց տալ՝</span>
                                        <Input
                                            readOnly={true}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onClick={() => setCountDropdown(!countDropdown)}
                                            value={count}
                                            name={"count"}
                                            // placeholder={`${models && models[0].networkName}`}
                                            type="text"
                                        />
                                        {countDropdown &&
                                        <Dropdown>
                                           {countArray?.map(model =>
                                                <li data-key={0} value={0}
                                                    onClick={(e) => {
                                                        setCount(model)
                                                        setCountDropdown(false)
                                                    }}>{model}
                                               </li>
                                            )}
                                        </Dropdown>}
                                    </Label>
                                </div>
                            </Label>
                            <SubmitButton type="submit" dark width>
                                Ցուցադրել
                            </SubmitButton>
                        </FormBody>
                    </WrapForm>}
            </Formik>
                                <div>
                                    {console.log('raitings',raitings)}
                                    {raitings ?
                                    <>
                                    <Pagination onChange={(e, page) => {
                                        setPage(page)
                                    }} count={total} shape="rounded"/>
                                    {raitings?.map(item => 
                                        <Review>
                                            <div className="d-flex">
                                                <RaitingDate>
                                                    {moment(item.creationDate).format('DD.MM.YYYY HH:MM')}
                                                </RaitingDate>
                                                <div className="stars">
                                                    {Array.from(Array(5), (e, i) =>
                                                        item?.value > i ?
                                                            <img src={selectedStar} alt=""/> :
                                                            <img src={disabledStar} alt=""/>
                                                    )}
                                                </div>
                                            </div>
                                            <p>{item.message}</p>
                                        </Review>
                                    )}
                                    </> : 'Այս մասնաճյուղի մասին կարծիքներ չկան'}
                                </div>
        </>
    )
};

export default Rating