
export async function IsLoggedIn(){
   
    let token = localStorage.getItem('token');
    const requestOptions={
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            'Authorization':"Bearer "+token
        },
        mode:'cors'
    }
    let isLogged =  await fetch('api/AdminAuthenticate/IsLoggedIn',requestOptions)
        .then(res=>{
            if(res.status === 401){
                return false;
            }
            else if(res.status===200){
                return true;
            } else { return false}
        });
    return isLogged;
}

export async function getUserDetails(){
    let token = localStorage.getItem('token');
    const requestOptions = {
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            'Authorization':"Bearer "+token
            // ,'Access-Control-Allow-Origin':'*'
        },
        mode:'cors'
    };
   let user = await fetch('api/AdminAuthenticate/GetUserDetails',requestOptions)
    // .then(function(response){
    //         //MAP that object thanks.
    //       return response.json()
    //     }
    // );
    const data = await user.json()
    if(data?.status === "Success"){
        localStorage.setItem("userId", data?.model?.id)
        localStorage.setItem("role", data?.model?.role)
        localStorage.setItem("icon",data.model.iconUrl)
    } else {
        localStorage.clear()
        window.location.reload()
    }
    return data
}

export function logOut(){
    // let navigate = useNavigate();
    localStorage.removeItem('token');
    // navigate('/login',{replace :true});
}
