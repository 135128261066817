import React, {useEffect, useState} from "react";
import "./styled.scss"
import {Formik} from "formik";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Blocked from "../../pictures/blocked.svg"
import Pagination from '@mui/material/Pagination';
import moment from "moment"
import NotBlocked from "../../pictures/notBlocked.svg"
import {Dropdown, FormBody, Input, Label, SubmitButton, WrapForm} from "../../components/styled";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Close from "../../pictures/close.svg"
import {useHistory} from "react-router";

const Users = () => {
    let token = localStorage.getItem('token');
    const [select, setSelect] = useState()
    const [pageData, setPageData] = useState()
    const [filter, setFilter] = useState(pageData?.filters[0])
    const [filterDropdown, setFilterDropdown] = useState(false)
    const [sort, setSort] = useState(pageData?.sortBy[0])
    const countArray = [10, 30, 50, 100]
    const [sortDropdown, setSortDropdown] = useState(false)
    const [countDropdown, setCountDropdown] = useState(false)
    const [users, setUsers] = useState()
    const [count, setCount] = useState(countArray[0])
    const [total, setTotal] = useState()
    const [page, setPage] = useState(1)
    const [days, setDays] = useState(['1', '2', '3'])
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState()
    const [selectedUserData, setSelectedUserData] = useState()
    const [selectedDayCount, setSelectedDayCount] = useState()
    const [dropdown, setDropdown] = useState(false)
    const userId = localStorage.getItem("userId")
    const history = useHistory()

    useEffect(()=>{
        setFilter(pageData?.filters[0])
        setSort(pageData?.sortBy[0])
    },[pageData])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function createData(id, image, name, phone, bc, reg, order, status) {
        return {id, image, name, phone, bc, reg, order, status};
    }

    const searchUser = async (value) => {
        const regexp = /\+374[0-9]{8}/;
        const match = value.match(regexp);
        setPage(1)
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        console.log('match', match);
        const phoneNumber = value.split('+')
        const response = await fetch(`api/UsersPage/SearchUser?${match ? `phoneNumber=%2B${phoneNumber[1]}` : `search=${value}`}&page=${page}`, requestOptions)
        const data = await response.json()
        if(response.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        response.status === 200 && setUsers(data.model?.users)
        setTotal(data?.model?.pageCount)
    }

    useEffect(async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const response = await fetch(`api/UsersPage/GetUserPageInfo`, requestOptions)
        const data = await response.json()
        if(response.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        console.log('data', data)
        setPageData(data.model)

        // const pageDataResponse = await fetch(`api/Network/GetFinancesPageInfo`, requestOptions)
        // const pageData = await pageDataResponse.json()
        // setNetworks(pageData.models)
    }, [])

    useEffect(()=>{
        if(!open){
            setSelectedUser(null)
            setSelectedUserData(null)
        }
    },[open])

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#EEEEEE',
            color: 'black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const setDropdowns = (item, type) => {
        if (type === "filter") {
            setFilter(item)
            setFilterDropdown(false)
        } else if (type === "sort") {
            setSort(item)
            setSortDropdown(false)
        } else if (type === "count") {
            setPage(1)
            setCount(item)
            setCountDropdown(false)
        }
    }

    const getUsers = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const response = await fetch(`api/UsersPage/GetUsers?state=${filter?.id}&type=${sort?.id}&number=${count}&pageNumber=${page}`, requestOptions)
        const data = await response.json()
        setUsers(data.model?.users)
        if(response.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        setTotal(data?.model?.pageCount)
    }
    useEffect(() => {
        sort && count && page && getUsers()
    }, [page])

    const blockUser = async (id) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const response = await fetch(`api/UsersPage/GetBlockInfo?userId=${id}`, requestOptions)
        if(response.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        const data = await response.json()
        setSelectedUser(data.model)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 4,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Formik
                    initialValues={{
                        reason: '',
                        userId: selectedUser?.id,
                    }}
                    onSubmit={(async values => {
                        const requestOptions = {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            mode: 'cors',
                        };
                        const response = await fetch(selectedUser?.blockDateEnd ? `/api/UsersPage/UnBlockUser?userId=${selectedUserData?.id}` : `/api/UsersPage/BlockUser?reason=${values.reason}&userId=${selectedUserData?.id}&monthCount=${selectedDayCount}`, requestOptions)
                        console.log('response', response);
                        if (response?.status === 200) {
                            setOpen(false)
                            getUsers()
                        }
                        // const data = await response.json()
                        // setFlags(data.models);
                    })}
                >
                    {props =>
                        <WrapForm onSubmit={props.handleSubmit}>
                            <Box sx={style}>
                                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "40px"}}>
                                    {selectedUser?.blockDateEnd ? "Վերականգնել" : "Արգելափակել"}
                                    <img style={{cursor: "pointer"}} onClick={()=>setOpen(false)} src={Close} alt=""/>
                                </div>
                                <div style={{marginBottom: "40px"}}>
                                    <img style={{height: "60px", width: "60px", borderRadius: "50%", marginRight: "16px", background: "#F2F2F2"}} src={selectedUserData?.iconUrl} alt=""/>
                                    <b>{selectedUser?.name || selectedUserData?.name}</b>
                                </div>
                                <div style={{
                                    rowGap: "30px", display: "flex", flexDirection: "column"}}>
                                    <Label dropdown={!selectedUser?.blockDateEnd} className={`select ${dropdown && "opened"}`} htmlFor="type">
                                        <span style={{color: "#2C2C2C", opacity: 0.5, marginBottom: "15px"}}>Արգելափակման ժամանակ`</span>
                                        {selectedUser?.blockDateEnd ?
                                            `${moment(selectedUser?.blockDateStart).format('DD.MM.YYYY')} - ${moment(selectedUser?.blockDateEnd).format('DD.MM.YYYY')}` :
                                            <>
                                                <Input
                                                    banners
                                                    readOnly={true}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    onClick={() => setDropdown(!dropdown)}
                                                    value={selectedDayCount && `${selectedDayCount} ամիս`}
                                                    name={"monthCount"}
                                                    type="text"
                                                />
                                                {dropdown && days &&
                                                <Dropdown>
                                                    {days?.map(day =>
                                                        <li data-key={0} value={0} onClick={(e) => {
                                                            setSelectedDayCount(day);
                                                            setDropdown(false)
                                                        }}>{day} ամիս
                                                        </li>
                                                    )}
                                                </Dropdown>}
                                            </>
                                        }
                                    </Label>
                                    <Label>
                                        <span style={{color: "#2C2C2C", opacity: 0.5, marginBottom: "15px"}}>Արգելափակման պատճառը`</span>
                                        {selectedUser?.blockDateEnd ?
                                            <p>{selectedUser?.blockReason}</p> :
                                            <Input
                                                banners
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props?.values?.reason}
                                                name={"reason"}/>
                                        }
                                    </Label>
                                    <SubmitButton width={"100%"} radius>{selectedUser?.blockDateEnd ? 'Վերականգնել' : 'Արգելափակել'}</SubmitButton>
                                </div>
                            </Box>
                        </WrapForm>}
                </Formik>
            </Modal>
            <h1>Օգտատերեր</h1>
            <div className={"d-flex justify-content-between"}>
                <Formik
                    initialValues={{}}
                    onSubmit={(async values => {
                        setFilterDropdown(false);
                        setSortDropdown(false);
                        setCountDropdown(false);
                        getUsers()
                    })}
                >
                    {props =>
                        <WrapForm onSubmit={props.handleSubmit}>
                            <FormBody noWrap flex alignEnd>
                                <Label dropdown className={`select ${select && "opened"}`} htmlFor="type">
                                    <span>Ֆիլտրացնել`</span>
                                    <Input
                                        readOnly={true}
                                        onClick={() => setFilterDropdown(!filterDropdown)}
                                        value={filter?.name || pageData?.filters[0].name}
                                        name={"type"}
                                        type="text"
                                    />
                                    {console.log('file', filterDropdown)}
                                    {filterDropdown && pageData?.filters &&
                                    <Dropdown>
                                        {pageData?.filters?.map(item =>
                                            <li onClick={() => setDropdowns(item, 'filter')} data-key={item?.id}
                                                value={item.id}>{item.name}
                                            </li>
                                        )}
                                    </Dropdown>}
                                </Label>
                                <Label dropdown className={`select ${select && "opened"}`} htmlFor="type">
                                    <span>Սորտավորել ըստ`</span>
                                    <Input
                                        readOnly={true}
                                        onClick={() => setSortDropdown(!sortDropdown)}
                                        value={sort?.name || pageData?.sortBy[0].name}
                                        name={"type"}
                                        placeholder={"ID-ի"}
                                        type="text"
                                    />
                                    {sortDropdown &&
                                    <Dropdown>
                                        {pageData?.sortBy?.map(item =>
                                            <li data-key={item.id} onClick={() => setDropdowns(item, 'sort')}
                                                value={item.id}>
                                                {item.name}
                                            </li>
                                        )}
                                    </Dropdown>}
                                </Label>
                                <Label show dropdown className={`select ${false && "opened"}`} htmlFor="type">
                                    <span>Ցույց տալ՝</span>
                                    <Input
                                        readOnly={true}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        onClick={() => setCountDropdown(!countDropdown)}
                                        value={count || countArray[0]}
                                        name={"count"}
                                        // placeholder={`${models && models[0].networkName}`}
                                        type="text"
                                    />
                                    {}
                                    {countDropdown &&
                                    <Dropdown>
                                        {countArray?.map(model =>
                                            <li data-key={model} value={0}
                                                onClick={(e) => setDropdowns(model, 'count')}>{model}
                                            </li>
                                        )}
                                    </Dropdown>}
                                </Label>
                                <SubmitButton type="submit" dark width>
                                    Ցուցադրել
                                </SubmitButton>
                            </FormBody>
                        </WrapForm>
                    }
                </Formik>
                <Label small style={{marginTop: "20px"}} htmlFor="type">
                    <span>Փնտրել օգտատեր՝</span>
                    <Input
                        name={"count"}
                        onChange={(e)=>searchUser(e.target.value)}
                        placeholder={"+374********"}
                        type="text"
                    />
                    </Label>
            </div>

            <Pagination style={{marginTop: "30px"}} onChange={(e, page) => {
                setPage(page)
            }} count={total} shape="rounded"/>
            <TableContainer style={{marginTop: "30px"}} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Նկար</StyledTableCell>
                            <StyledTableCell align="center">Անուն</StyledTableCell>
                            <StyledTableCell align="center">Հեռ․</StyledTableCell>
                            <StyledTableCell align="center">BCoins</StyledTableCell>
                            <StyledTableCell align="center">Գր․ օր</StyledTableCell>
                            <StyledTableCell align="center">Պատվերներ</StyledTableCell>
                            <StyledTableCell align="center">Ստատուս</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {users?.map((row) => (
                            <StyledTableRow
                                key={row.id}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.iconUrl && <img style={{
                                        width: '60px',
                                        height: "60px",
                                        borderRadius: "50%",
                                        objectFit: "contain"
                                    }} src={row.iconUrl}/>}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">{row.phoneNumber}</StyledTableCell>
                                <StyledTableCell align="center">{row.busyCoins}</StyledTableCell>
                                <StyledTableCell align="center">{moment(row.registrationDate).format('DD.MM.YYYY')}</StyledTableCell>
                                <StyledTableCell style={{cursor: "pointer"}} onClick={() => {
                                    history.push(`historyByUsers#${row?.id}`)
                                }} align="center">Բացել</StyledTableCell>
                                <StyledTableCell align="center" onClick={() => {
                                    setSelectedUserData(row)
                                    row?.isBlocked && blockUser(row?.id)
                                    setOpen(true)
                                }}>{row.isBlocked ?
                                    <img src={Blocked}/> : <img src={NotBlocked}/>}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Users