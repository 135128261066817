import React, { Component } from 'react';
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, SubMenu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import './styles.scss';
import Logo from './busyLogo'
import { getUserDetails } from "../Login/Authentication";
import "../Layout.scss";

export class Sidebar extends Component {
    static displayName = Sidebar.name;

    constructor(props) {
        super(props);
        this.state = {
            selected: window.location.pathname
        };
    }

    componentDidMount() {
        let user = getUserDetails();
        getUserDetails().then(res => {
            localStorage.setItem("role", res.model.role)
            localStorage.setItem("icon", res.model.iconUrl)
            this.setState({ user: res.model.role }, () => console.log('state', this.state.user))
        }).catch(error => {
            if (error.status === 500) {
                alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
            }
        });

    }

    render() {
        return (
            <div className="SidebarStyle sticky-top">
                <ProSidebar>
                    <SidebarHeader>
                        <div className="Busylogo">
                            <Logo />
                        </div>
                        <p className="AdminPanelHeading">
                            Ադմին հարթակ
                        </p>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square">

                            {this.state.user == 'SuperAdmin'
                                ?
                                <div>
                                    <MenuItem>
                                        <Link to={"/finances"}>
                                            Ֆինանսներ
                                        </Link>
                                    </MenuItem>
                                    <SubMenu title="Ցանցեր">
                                        <MenuItem>
                                            <Link to="/networks">• Ցանցեր և մասնաճյուղեր</Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to="/networks/categories">• Կատեգորիաներ</Link>
                                        </MenuItem>
                                    </SubMenu>
                                    <MenuItem active={this.state.selected.includes('managers')}>
                                        <Link to="/managers">Մենեջերներ</Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to="/users">Օգտատերեր</Link>
                                    </MenuItem>
                                    <MenuItem><Link to={"/ads"}>Գովազդներ</Link></MenuItem>
                                    <SubMenu title="Պատվերների պատմություն">
                                        <MenuItem>
                                            <Link to="/history">Ըստ ցանցերի</Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to="/historyByUsers">Ըստ օգտատերերի</Link>
                                        </MenuItem>
                                    </SubMenu>
                                    <MenuItem>
                                        <Link to="/tellRef">Tellcell չեղարկումներ</Link>
                                    </MenuItem>
                                </div>
                                : this.state.user == 'Manager'
                                    ?
                                    <div>
                                        {/*<MenuItem>*/}
                                        {/*    <Link to={"/finances"}>*/}
                                        {/*        Ֆինանսներ*/}
                                        {/*    </Link>*/}
                                        {/*</MenuItem>*/}
                                        <SubMenu title="Ցանցեր">
                                            <MenuItem>
                                                <Link to="/networks">• Ցանցեր և մասնաճյուղեր</Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/networks/categories">• Կատեգորիաներ</Link>
                                            </MenuItem>
                                        </SubMenu>
                                        <MenuItem>
                                            <Link to="/users">Օգտատերեր</Link>
                                        </MenuItem>
                                        <MenuItem><Link to={"/ads"}>Գովազդներ</Link></MenuItem>
                                        <SubMenu title="Պատվերների պատմություն">
                                            <MenuItem>
                                                <Link to="/history">Ըստ ցանցերի</Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/historyByUsers">Ըստ օգտատերերի</Link>
                                            </MenuItem>
                                        </SubMenu>
                                    </div>
                                    : this.state.user == 'Network'
                                    &&
                                    <div>
                                        <MenuItem>
                                            <Link to={"/finances"}>
                                                Ֆինանսներ
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to={"/banners"}>
                                                Բաններներ
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to={"/rating"}>
                                                Վարկանիշ
                                            </Link>
                                        </MenuItem>
                                        <MenuItem>
                                            <Link to="/history">Պատվերների պատմություն</Link>
                                        </MenuItem>
                                        <SubMenu title="Մենյու">
                                            <MenuItem>
                                                <Link to={"/items"}>• Տեսականի</Link>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link to="/items/categories">• Կատեգորիաներ</Link>
                                            </MenuItem>
                                        </SubMenu>
                                        <MenuItem>
                                            <Link to={"/ads"}>Գովազդ</Link>
                                        </MenuItem>
                                    </div>
                            }
                            <MenuItem onClick={() => {
                                this.props.logOut()
                            }}>
                                <Link to="/"> Ելք </Link>
                            </MenuItem>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <p className="PrivacyPolicy">
                            <Link to="/privacy" style={{ textDecoration: 'underline', color: 'gray' }}> Privacy Policy </Link>
                        </p>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        );
    }
}
