import React, {useEffect, useState} from "react";
import {
    FormBody,
    InfoText,
    InfoWrapper,
    Input,
    Label,
    PageTitle,
    SubmitButton,
    WrapForm,
    Dropdown,
    CheckButton,
    DatePickerInput, Loading
} from "../../components/styled";
import {Formik} from "formik";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import "antd/dist/antd.css";
import Box from '@mui/material/Box';
import moment from "moment"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DatePicker } from 'antd';
import {Alert, Modal} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const History = () => {
    const [models, setModels] = useState()
    const [selected, setSelected] = useState()
    const [orders, setOrders] = useState()
    let token = localStorage.getItem('token');
    const userId = localStorage.getItem("userId")
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const countArray = [10, 30, 50, 100]
    const [page, setPage] = useState(1)
    const [count, setCount] = useState()
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [countDropdown, setCountDropdown] = useState(false)
    const [branchesDropdown, setBranchesDropdown] = useState(false)
    const [selectedModel, setSelectedModel] = useState()
    const [modelsDropdown, setModelsDropdown] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState()
    const [total, setTotal] = useState()
    const [check, setCheck] = useState()
    const role = localStorage.getItem('role')
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    useEffect(async () => {
        console.log('asdasd');
        const modelsRequestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const getModels = await fetch(`api/Network/GetModelsForDropDown`, modelsRequestOptions)
        const modelsData = await getModels.json();
        if (getModels.status === 500) {
            alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
        }
        if(getModels.status === 200){
            role === "Network" ?  setSelected(modelsData.models.find(item=>item?.networkId == userId).branches) : setModels(modelsData.models)
        }else if(getModels.status === 401){
            localStorage.clear();
            window.location.reload()
        }
    }, [])

    const selectType = (value, type) => {
        console.log('value',value)
        if (type === 'branch') {
            setSelectedBranch(value)
            setBranchesDropdown(false)
        } else {
            console.log('else', selectedBranch);
            setSelected(value)
            setModelsDropdown(false)
            setSelectedBranch(null)
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#EEEEEE',
            color: 'black',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const getHistory = async(submit) => {
     if(selectedBranch && count && startDate && endDate && page){
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };
            const data = await fetch(`api/Order/GetHistory?branchId=${selectedBranch[0]}&number=${count}&from=${startDate}&to=${endDate}&pageNumber=${page}`, requestOptions)
            const raitingData = await data.json();
            if(data.status === 401){
                localStorage.clear();
                window.location.reload()
            }
            if(data.status === 200){
                setStatus("success")
                setOrders(raitingData.model?.reservations)
                setTotal(raitingData.model?.pages)
            } else if (data.status === 500) {
                setStatus("error")
                setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
            } else {
                setStatus("error")
                setErrorMessage(data.message)
            }
        }else if(submit) {
            setStatus("error");
            setErrorMessage('Լրացրեք բոլոր դաշտերը')
        }
    }

    const getCheck = async id => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        const data = await fetch(`api/PersonalPage/GetCheck?resId=${id}`, requestOptions)
        const raitingData = await data.json();
        if(data.status === 401){
            localStorage.clear();
            window.location.reload()
        } else if (data.status === 500) {
            setStatus("error")
            setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
        } 
        raitingData.status === 'Success' && setCheck(raitingData.model)
    }

    useEffect(()=> {
        getHistory()
    },[page])
    
    return(
        <>
            {status === "error" ?
                <Alert style={{marginBottom: "20px"}} severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress color="inherit"/>
                </Loading>
            }
        <PageTitle>
        Պատվերների պատմություն
        </PageTitle>
        
        <Formik
            initialValues={{
            }}
                onSubmit={(async (values) => {
                    setStatus('loading');
                    getHistory("submit")
                })}
            >
                {props =>
                    <WrapForm onSubmit={props.handleSubmit}>
                        <FormBody alignEnd maxWidth={"1500px"}>
                            <div>
                                <div style={{columnGap: '20px'}} className={"d-flex align-items-center mt-3"}>
                                    {role !== "Network" &&
                                    <Label dropdown className={`select ${false && "opened"}`} htmlFor="type">
                                        <span>Ցանց՝</span>
                                        <Input
                                            readOnly={true}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onClick={() => {
                                                console.log('ok')
                                                setModelsDropdown(!modelsDropdown)
                                            }}
                                            value={selected?.networkName}
                                            name={"branch"}
                                            type="text"
                                        />
                                        {(modelsDropdown && models) &&
                                        <Dropdown>
                                            {models?.map(model =>
                                                <li data-key={0} value={0}
                                                    onClick={(e) => selectType(model)}>{model.networkName}
                                                </li>
                                            )}
                                        </Dropdown>}
                                    </Label>}
                                    <Label dropdown className={`select ${false && "opened"}`} htmlFor="type">
                                        <span>Մասնաճյուղ՝</span>
                                        <Input
                                            readOnly={true}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onClick={() => {
                                                console.log('ok')
                                                selected && setBranchesDropdown(!branchesDropdown)
                                            }}
                                            value={selectedBranch ? selectedBranch[1] : ''}
                                            name={"branch"}
                                            type="text"
                                        />
                                        {console.log('selected',selected)}
                                        {(branchesDropdown && selected) &&
                                        <Dropdown>
                                             {role === "Network" ? Object.entries(selected)?.map((branch) =>
                                                 <li data-key={0} value={0}
                                                     onClick={(e) => selectType(branch, 'branch')}>{branch[1]}
                                                 </li>
                                             ) : Object.entries(selected?.branches)?.map((branch) =>
                                                        <li data-key={0} value={0}
                                                            onClick={(e) => selectType(branch, 'branch')}>{branch[1]}
                                                        </li>
                                                    )}
                                        </Dropdown>}
                                    </Label>
                                    <Label>
                                    Ժամանակահատված՝
                                <div style={{columnGap: '5px'}} className={"d-flex align-items-center mt-3"}>
                                    <DatePickerInput
                                        id="date"
                                        format={"DD.MM.YYYY"}
                                        onChange={(e, dateString) => {
                                            setStartDate(moment(dateString, "DD.MM.YYYY").format('YYYY-MM-DD'))
                                        }}
                                        placeholder={''}
                                        suffixIcon={null}
                                    />

                                    -
                                    <DatePickerInput
                                        banners
                                        format={"DD.MM.YYYY"}
                                        id="date"
                                        placeholder={''}
                                        suffixIcon={null}
                                        onChange={(e, dateString) => {
                                            console.log('dateString', moment(dateString, "DD.MM.YYYY").format('YYYY-MM-DD'));
                                            setEndDate(moment(dateString, "DD.MM.YYYY").format('YYYY-MM-DD'))
                                        }}
                                    />
                                </div>
                            </Label>
                                    <Label show dropdown className={`select ${false && "opened"}`} htmlFor="type">
                                        <span>Ցույց տալ՝</span>
                                        <Input
                                            readOnly={true}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            onClick={() => setCountDropdown(!countDropdown)}
                                            value={count}
                                            name={"count"}
                                            // placeholder={`${models && models[0].networkName}`}
                                            type="text"
                                        />
                                        {countDropdown &&
                                        <Dropdown>
                                           {countArray?.map(model =>
                                                <li data-key={0} value={0}
                                                    onClick={(e) => {
                                                        setCount(model)
                                                        setPage(1)
                                                        setCountDropdown(false)
                                                    }}>{model}
                                               </li>
                                            )}
                                        </Dropdown>}
                                    </Label>
                                </div>
                            </div>
                            <SubmitButton type="submit" dark width>
                                {status === "loading" ? "Բեռնեում․․․" : "Ցուցադրել"}
                            </SubmitButton>
                        </FormBody>
                    </WrapForm>}
            </Formik>
            <div style={{marginTop: "30px"}}>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box style={{border: "none"}} sx={style}>
                    <p className={"d-flex justify-content-between"} style={{marginBottom: "30px"}}>{moment(check?.reservationTime).format('DD.MM.YYYY  HH:MM')} <span style={{color: "#FF5757"}}>Կոդ՝ {check?.reservationId}</span></p>
                    {check?.items?.map((item)=> 
                    <div className={"d-flex justify-content-between"} style={{borderBottom: "1px solid #F6F6F6", padding: "16px 0"}}>
                        <span>{item.name}</span>
                        <span>{item.count}</span>
                        <span>{item.price}</span>
                        <span>{item.priceSum}</span>
                    </div>
                    )}
                </Box>
            </Modal>
            <Pagination onChange={(e, page) => {
                setPage(page)
            }} count={total} shape="rounded"/>
                    <TableContainer style={{marginTop: "30px", boxShadow: "none"}} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Ամսաթիվ</StyledTableCell>
                                <StyledTableCell>Կոդ</StyledTableCell>
                                <StyledTableCell>Գումար</StyledTableCell>
                                <StyledTableCell>Չեկ</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {orders?.map((order) => (
                                <StyledTableRow
                                    key={order.reservationTime}
                                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                     <StyledTableCell component="th" scope="row">
                                         {moment(order.reservationTime).format('YYYY.MM.DD HH:MM')}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {order.reservationId}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {order.totalSum}
                                    </StyledTableCell>
                                    <StyledTableCell><CheckButton onClick={()=>{
                                        getCheck(order?.reservationId)
                                        setOpen(true)
                                    }}>Տեսնել</CheckButton></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
};

export default History