import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import imageUpload from "../../pictures/imageUpload.svg"
import { useHistory } from "react-router";
import {
    FormBody,
    FormHeader,
    Input,
    Label,
    PageTitle,
    SubmitButton,
    WrapForm, CheckboxWrapper, SubTitle, Dropdown, Loading
} from "../../components/styled";
import { Checkbox } from 'antd';
import { Alert } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const AddNewNetwork = ({ edit }) => {
    let token = localStorage.getItem('token');
    const history = useHistory()
    const [network, setNetwork] = useState()
    const [id, setId] = useState(window.location.hash.slice(1))
    const [type, setType] = useState(false)
    const [selected, setSelected] = useState(0)
    const [selectedValue, setSelectedValue] = useState()
    const [icon, setIcon] = useState()
    const [background, setBackground] = useState()
    const [networkImage, setNetworkImage] = useState()
    const [percent, setPercent] = useState()
    const [flags, setFlags] = useState()
    const [flagsForEdit, setFlagsForEdit] = useState([])
    const [editedFlags, setEditedFlags] = useState([])
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const previewFile = (imageType) => {
        let preview = imageType === "backgroundImage" ? document.getElementById('uploadBackground') :
            imageType === "networkImage" ? document.getElementById('uploadNetworkImage') :
                document.getElementById('uploadImg');
        let file = imageType === "backgroundImage" ? document.getElementById('backgroundImage').files[0]
            : imageType === "networkImage" ? document.getElementById('networkImage').files[0]
                : document.getElementById('icon').files[0];
        let reader = new FileReader();
        reader.onloadend = function (e) {
            preview.src = reader.result;
            imageType === "backgroundImage" ? setBackground(reader.result.split(',')[1])
                : imageType === "networkImage" ? setNetworkImage(reader.result.split(',')[1])
                    : setIcon(reader.result.split(',')[1]);
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = '';
        }
    }

    useEffect(async () => {
        if (id) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };
            const response = await fetch(`api/Network/GetNetworkDetails?networkId=${id}`, requestOptions)
            const data = await response.json()
            setNetwork(data.model)
            if (response.status === 401) {
                localStorage.clear();
                window.location.reload()
            }
            setPercent(data.model?.percent)
            selectType(data.model?.type)
            setFlagsForEdit([...data.model?.flags])
        }
    }, [])


    const onChange = (checkedValues) => {
        const test = flags?.map(flag => checkedValues.includes(flag.name) && flag)
        setEditedFlags(test?.filter(Boolean))
    };

    const selectedIds = flagsForEdit && flagsForEdit?.filter(item => item.isSelected)
    const editIds = flagsForEdit && flagsForEdit?.map(item => ({
        id: item.flagId,
        value: item.name,
        label: item.name
    }))
    const allIds = flags && flags?.map(item => ({
        id: item.flagId,
        value: item.name,
        label: item.name
    }))


    useEffect(() => {
        let test;
        if (flagsForEdit.length !== 0) {
            test = flagsForEdit?.filter(item => item.isSelected)
            if (edit && test?.length) {
                onChange(test?.map(item => item.name))
            }
        }
    }, [flagsForEdit])

    const final = selectedIds?.map(item => item.name)

    useEffect(async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
        };
        const response = await fetch('/api/NetworkFlag/GetFlags', requestOptions)
        const data = await response.json()
        if (response.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        if (response.status === 500) {
            alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
        }
        setFlags(data.models);
    }, [])
    const selectType = (value) => {
        setSelected(value)
        value === 0 ?
            setSelectedValue("Սրճարան") : value === 1 ?
                setSelectedValue("Արագ սնունդ") : setSelectedValue("Ռեստորան")
        setType(false)
    }
    useEffect(() => {
        setSelected(network?.type)
    }, [network])

    return (
        <>
            {status === "error" ?
                <Alert severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress style={{ color: "white" }} />
                </Loading>
            }
            <Formik
                enableReinitialize
                initialValues={{
                    networkId: Number(id),
                    name: network?.name,
                    username: network?.userName,
                    description: network?.description,
                    percent: network?.percent
                }}
                onSubmit={async (values) => {
                    setStatus("loading")
                    values["icon"] = icon;
                    values["backgroundImage"] = background;
                    values["networkImage"] = networkImage;
                    values["type"] = selected;
                    (!values["password"] || values["password"]?.length < 1) && delete values["password"];
                    (values["oldPassword"]?.length < 1) && delete values["oldPassword"];
                    values["flags"] = editedFlags
                    const requestOptions = {
                        method: edit ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        mode: 'cors',
                        body: JSON.stringify(values)
                    };
                    const url = edit ? `api/AdminAuthenticate/UpdateNetwork` : '/api/AdminAuthenticate/RegisterNetwork'
                    const request = await fetch(url, requestOptions)
                    const response = await request.json()
                    if (request.status === 200) {
                        history.push('/networks')
                        setStatus('success')
                    } else if (request.status === 500) {
                        setStatus("error")
                        setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
                    } else {
                        setStatus('error')
                        setErrorMessage(response?.message || response?.title)
                    }
                }}>
                {props => (
                    props?.values &&
                    <WrapForm autoComplete="off" className={"wrap branch"} onSubmit={props.handleSubmit}>
                        <FormHeader className={"d-flex w-100"}>
                            <PageTitle>{edit ? 'Փոփոխել ցանցը' : 'Ավելացնել նոր ցանց'}</PageTitle>
                            <SubmitButton className='addNew'
                                type={"submit"}>{status === "loading" ? "Բեռնում․․․" : edit ? "Փոփոխել" : "Ավելացնել"}</SubmitButton>
                        </FormHeader>
                        <div className={"d-flex w-100 justify-content-between gap-4"}>
                            <div className={"imageUploadWrapper"}>
                                <SubTitle style={{ width: "100%" }}>Ցանցի լոգո</SubTitle>
                                <Label full imageUpload>
                                    <Field
                                        name={'backgroundImage'}
                                        id={'backgroundImage'}
                                        type={'file'}
                                        onChange={() => {
                                            previewFile("backgroundImage");
                                        }}
                                        value={""}
                                    />
                                    <img src={(edit && network) ? network?.backgroundImageUrl : imageUpload} id="uploadBackground"
                                        alt="upload" />
                                </Label>
                                <div className={"d-flex justify-content-between"}>
                                    <Label round imageUpload>
                                        <Field
                                            name={'icon'}
                                            id={'icon'}
                                            type={'file'}
                                            onChange={() => {
                                                previewFile();
                                            }}
                                            value={""}
                                        />
                                        <img src={edit ? network?.iconUrl : imageUpload} id="uploadImg" alt="upload" />
                                    </Label>
                                    <Label small imageUpload>
                                        <Field
                                            name={'networkImage'}
                                            id={'networkImage'}
                                            type={'file'}
                                            onChange={() => {
                                                previewFile("networkImage");
                                            }}
                                            value={""}
                                        />
                                        <img src={edit ? network?.imageUrl : imageUpload} id="uploadNetworkImage"
                                            alt="upload" />
                                    </Label>
                                </div>
                            </div>
                            <FormBody>
                                <SubTitle style={{ width: "100%" }}>Ընդհանուր ինֆորմացիա</SubTitle>
                                <Label htmlFor="name">
                                    <span>Անվանում</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props?.values?.name}
                                        name={"name"}
                                        placeholder={"Ցանցի անվանում"}
                                        type="text" />
                                </Label>
                                <Label htmlFor="description">
                                    <span>Նկարագրություն</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props?.values?.description}
                                        name={"description"}
                                        placeholder={"Ցանցի նկարագիր"}
                                        type="text" />
                                </Label>
                                <Label htmlFor="description">
                                    <span>Ընտրել տոկոս</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props?.values?.percent}
                                        name={"percent"}
                                        min={1}
                                        onWheel={event => event.currentTarget.blur()}
                                        placeholder={"Ընտրել տոկոս"}
                                        type="number" />
                                </Label>
                                <Label dropdown className={`select ${type && "opened"}`} htmlFor="type">
                                    <span>Տեսակ</span>
                                    <Input
                                        readOnly={true}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        onClick={() => setType(!type)}
                                        value={selectedValue}
                                        name={"type"}
                                        type="text"
                                    />
                                    {type &&
                                        <Dropdown>
                                            <li data-key={0} value={0} onClick={(e) => selectType(e.target.value)}>Սրճարան
                                            </li>
                                            <li data-key={1} value={1} onClick={(e) => selectType(e.target.value)}>Արագ
                                                սնունդ
                                            </li>
                                            <li data-key={2} value={2}
                                                onClick={(e) => selectType(e.target.value)}>Ռեստորան
                                            </li>
                                        </Dropdown>}
                                </Label>
                                <Label htmlFor={"username"}>
                                    <span>Գաղտնանուն</span>
                                    <Input
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props?.values?.username}
                                        name={"username"}
                                        placeholder={"Մուտքագրեք գաղտնանունը"}
                                        type="text" />
                                </Label>

                                {edit ?
                                    <>
                                        <Label htmlFor={"oldPassword"}>Գաղտնաբառ
                                            <Field
                                                autoComplete={"new-password"}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                name={"oldPassword"}
                                                placeholder={"Մուտքագրեք հին գաղտնաբառը"}
                                                type="password" />
                                        </Label>
                                        <Label htmlFor="newPassword">
                                            <span>Նոր գաղտնաբառ</span>
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props?.values?.newPassword}
                                                name={"newPassword"}
                                                placeholder={"Մուտքագրեք նոր գաղտնաբառը"}
                                                type="password" />
                                        </Label>
                                    </> :
                                    <Label htmlFor={"password"}>Գաղտնաբառ
                                        <Field
                                            autoComplete={"new-password"}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            name={"password"}
                                            placeholder={"Մուտքագրեք գաղտնաբառը"}
                                            type="password" />
                                    </Label>
                                }
                                <Label full>
                                    <span>Կատեգորիա</span>
                                    <CheckboxWrapper role="group">
                                        {edit ? (final.length && <CheckboxWrapper role="group">
                                            <Checkbox.Group options={editIds} defaultValue={final} onChange={onChange} />
                                        </CheckboxWrapper>) : <CheckboxWrapper role="group">
                                            <Checkbox.Group options={allIds} onChange={onChange} />
                                        </CheckboxWrapper>}
                                    </CheckboxWrapper>
                                </Label>
                            </FormBody>
                        </div>
                    </WrapForm>
                )}
            </Formik>
        </>
    )
};

export default AddNewNetwork