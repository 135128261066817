import React, {useEffect, useState} from "react";
import {
    FormBody,
    FormHeader,
    Input,
    Label, Loading,
    PageTitle,
    SubmitButton,
    Trash,
    WrapForm
} from "../../components/styled";
import {Field, Formik} from "formik";
import {AdComponent} from "../Ads/styled";
import imageUpload from "../../pictures/imageUpload.svg";
import trash from "../../pictures/trash.png";
import {Alert, Modal} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Banners = () => {
    let token = localStorage.getItem('token');
    const userId = localStorage.getItem("userId")
    const [banners, setBanners] = useState()
    const [image, setImage] = useState()
    const [today, setToday] = useState()
    const [date, setDate] = useState()
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState()

    const getBanners = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const getBanners = await fetch(`api/Banner/GetBanners?networkId=${userId}`, requestOptions)
        const bannersData = await getBanners.json();
        if (getBanners.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        getBanners.status === 200 && setBanners(bannersData.models)
    }
    const deleteBanner = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deleteBanner = await fetch(`api/Banner/RemoveBanner?id=${id}`, requestOptions)
        deleteBanner.status == '200' && window.location.reload()
    }
    const previewFile = () => {
        let preview = document.getElementById('uploadImg');
        let file = document.getElementById('image').files[0];
        let reader = new FileReader();
        reader.onloadend = function (e) {
            preview.src = reader.result;
            setImage(reader.result.split(',')[1]);
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = '';
        }
    }
    const getToday = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        setToday(today);
    }
    useEffect(() => {
        getBanners()
        getToday()
    }, [])

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <div className={"modalWindow"}>
                    <div className="modalBox">
                        {`Ջնջե՞լ գովազդը`}
                        <div>
                            <SubmitButton className='addNew'
                                          onClick={() => deleteBanner(selected?.id)}>Ջնջել</SubmitButton>
                            <SubmitButton className='addNew cancelButton'
                                          onClick={() => setOpen(false)}>Զեղարկել</SubmitButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <PageTitle>Բաններներ</PageTitle>
            <FormBody margin={"0 0 70px"}>
                {(banners?.length > 0) ? banners.map(banner =>
                        <AdComponent banner>
                            <img src={banner.imageURL}/>
                            <Trash onClick={() => {
                                setSelected(banner);
                                setOpen(true)
                            }}>
                                <img src={trash}/></Trash>
                        </AdComponent>
                    ) :
                    <AdComponent banner>
                        <div/>
                    </AdComponent>
                }
            </FormBody>
            {banners?.length < 5 &&
            <>
                {status === "error" ?
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                    : status === "loading" &&
                    <Loading>
                        <CircularProgress color="inherit"/>
                    </Loading>
                }
                <Formik
                    enableReinitialize
                    initialValues={{
                        // name: "kkkk",
                        // address: branch?.address || "",
                        // userName: branch?.userName || "",
                        // password: branch?.password || "",
                        // branchId: id,
                    }}
                    onSubmit={async (values, actions) => {
                        setStatus("loading")
                        console.log('data', date);
                        values["imageURL"] = image;
                        values["networkID"] = userId;
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify(values)
                        };
                        const addedBanner = await fetch(`api/Banner/AddBanner`, requestOptions)
                        const bannersData = await addedBanner.json();
                        if (bannersData.status === 200) {
                            setStatus('success')
                        } else if (addedBanner.status === 500) {
                            setStatus("error")
                            setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
                        } else {
                            setStatus('error')
                            setErrorMessage(bannersData?.message || bannersData?.title)
                        }
                        bannersData.status === 'Success' && await getBanners()
                    }}>
                    {props =>
                        <WrapForm onSubmit={props.handleSubmit}>
                            <FormHeader>
                                <h3>Ավելացնել նոր բաններ</h3>
                            </FormHeader>
                            <div className={"d-flex"} style={{marginTop: '30px'}}>
                                <div style={{marginRight: "20px"}}>
                                    <Label imageUpload full bannerUpload>
                                        <Field
                                            name={'image'}
                                            id={'image'}
                                            type={'file'}
                                            onChange={() => {
                                                previewFile();
                                            }}
                                            value={""}
                                        />
                                        <img src={imageUpload} id="uploadImg" alt="upload"/>
                                    </Label>
                                </div>
                                <FormBody maxWidth={"500px"}>

                                </FormBody>
                            </div>
                            <SubmitButton type={"submit"}>{status === "loading" ? "Բեռնում․․․" : "Ավելացնել"}</SubmitButton>
                        </WrapForm>
                    }
                </Formik>
            </>
            }
        </>
    )
};

export default Banners