import React, { useEffect, useState } from "react";
import "./styled";
import { useHistory } from "react-router-dom";
import disabledStar from "../../pictures/disablesStar.svg";
import selectedStar from "../../pictures/selectedStar.svg";
import edit from "../../pictures/edit.png";
import blackEdit from "../../pictures/blackEdit.svg";
import trash from "../../pictures/trash.png";
import whiteTrash from "../../pictures/whiteTrash.svg";
import {
  Edit,
  FormHeader,
  Icon,
  NavigationLink,
  PageTitle,
  Row,
  SubmitButton,
  Trash,
} from "../../components/styled";
import { IconWrapper, NetworkWrapper } from "./styled";
import { Modal } from "@mui/material";

const Networks = () => {
  let token = localStorage.getItem("token");
  const [branches, setBranches] = useState();
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const [network, setNetwork] = useState({});
  const [branch, setBranch] = useState();
  const [branchOpen, setBranchOpen] = useState(false);
  const history = useHistory();
  const [cardList, setCardList] = useState([])
  const [currentCategory, setCurrentCategory] = useState(null)
  const getNetworks = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let response = await fetch(
      "api/Network/GetNetworksForManager",
      requestOptions
    );
    const data = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    setBranches(data.models);
    selected &&
      data.models.map((item) => item.id === selected.id && setSelected(item));
  };
  const saveNetworksOrder = async (changeList) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(changeList)
    };
    await fetch(
      "api/Network/SaveNetworksOrder",
      requestOptions,
    );
  }
  useEffect(() => {
    getNetworks();
  }, []);
  const selectBranch = (branch) => {
    setSelected(branch);
  };
  const deleteBranch = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      mode: "cors",
    };
    let response = await fetch(
      `api/AdminAuthenticate/DeleteBranch?branchId=${id}`,
      requestOptions
    );
    const data = await response.json();
    if ((data.status = "Success")) {
      getNetworks();
      selectBranch(selected);
      setBranchOpen(false);
    }
  };
  const deleteNetwork = async (id, disabled) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      mode: "cors",
    };
    await fetch(
      `api/AdminAuthenticate/changeStateNetwork?networkId=${id}&isDisabled=${!disabled}`,
      requestOptions
    );
    setOpen(false);
    await getNetworks();
  };

  useEffect(() => {
    setCardList(branches ? [...branches] : [])
  }, [branches])

  function dragStartHandler(e, category) {
    setCurrentCategory(category)
  }
  function dragDropHandler(e, category) {
    e.preventDefault()
    if (category?.type === currentCategory.type) {
      e.target.style.background = '#F2F2F2'
      let changeList = cardList.map(item => {
        if (item.id === category.id) {
          return { ...item, order: currentCategory.order }
        }
        if (item.id === currentCategory.id) {
          return { ...item, order: category.order }
        }
        return item
      })
      setCardList(changeList)
      saveNetworksOrder(changeList)
    }
  }
  function dragOverHandler(e, category) {
    e.preventDefault()
    if (category?.type === currentCategory.type) {
      e.target.style.background = 'lightgray'
    }

  }

  function dragLeaveHandler(e) {
    e.target.style.background = '#F2F2F2'
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div className={"modalWindow"}>
          <div className="modalBox">
            {!network?.disabled
              ? "Ապաակտիվացնե՞լ"
              : "Ակտիվացնել"}
            <div>
              <SubmitButton
                className="addNew"
                onClick={() => deleteNetwork(network?.id, network?.disabled)}
              >
                {!network?.disabled ? "Ապաակտիվացնե՞լ" : "Ակտիվացնել"}
              </SubmitButton>
              <SubmitButton
                className="addNew cancelButton"
                onClick={() => setOpen(false)}
              >
                Չեղարկել
              </SubmitButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={branchOpen}
        onClose={() => setBranchOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div className={"modalWindow"}>
          <div className="modalBox">
            {`Ջնջե՞լ ${branch?.address}-ը `}
            <div>
              <SubmitButton
                className="addNew"
                onClick={() => deleteBranch(branch?.id)}
              >
                Ջնջել
              </SubmitButton>
              <SubmitButton
                className="addNew cancelButton"
                onClick={() => setBranchOpen(false)}
              >
                Չեղարկել
              </SubmitButton>
            </div>
          </div>
        </div>
      </Modal>
      <NetworkWrapper>
        <FormHeader>
          <PageTitle>Ցանցեր և մասնաճյուղեր</PageTitle>
          <NavigationLink right to={"/networks/addNew"} className="addNew">
            Ստեղծել ցանց
          </NavigationLink>
        </FormHeader>
        <div>
          {cardList?.find(item => item?.type === 0) && <h2>Սրճարաններ</h2>}
          <ul className="list">
            {cardList
              ?.filter(item => item.type === 0)
              .sort((a, b) => a.order - b.order).map(branch => (
                <Row
                  index
                  selected={selected?.id === branch?.id}
                  largeRow
                  disabled={branch?.disabled}
                  key={branch.id}
                  className="branchRow"
                  onClick={() => selectBranch(branch)}
                  draggable
                  onDragStart={(e) => dragStartHandler(e, branch)}
                  onDragLeave={dragLeaveHandler}
                  onDragOver={(e) => dragOverHandler(e, branch)}
                  onDrop={(e) => dragDropHandler(e, branch)}
                >
                  <IconWrapper>
                    <Icon className={"icon"} src={`${branch?.iconUrl}`} alt="" />
                    <p>{branch.name}</p>
                  </IconWrapper>
                  <div className="stars">
                    {Array.from(Array(5), (e, i) =>
                      branch?.rating > i ? (
                        <img key={i} src={selectedStar} alt="" />
                      ) : (
                        <img key={i} src={disabledStar} alt="" />
                      )
                    )}
                  </div>
                  <div>
                    {!branch?.disabled && <Edit
                      selected={selected?.id === branch.id}
                      onClick={() => history.push(`/editNetworks#${branch?.id}`)}
                      className="edit"
                    >
                      <img src={selected?.id === branch.id ? blackEdit : edit} alt="edit" />
                    </Edit>}
                    <Trash
                      onClick={() => {
                        setOpen(true);
                        setNetwork(branch);
                      }}
                      className="trash"
                    >
                      <img
                        src={selected?.id === branch.id ? whiteTrash : trash}
                        alt="trash"
                      />
                    </Trash>
                  </div>
                </Row>
              ))}
          </ul>
          {cardList?.find(item => item?.type === 1) && <h2>Արագ Սնունդ</h2>}
          <ul className="list">
            {cardList
              ?.filter(item => item.type === 1)
              .sort((a, b) => a.order - b.order).map(branch => (
                <Row
                  index
                  selected={selected?.id === branch?.id}
                  largeRow
                  disabled={branch?.disabled}
                  key={branch.id}
                  className="branchRow"
                  onClick={() => selectBranch(branch)}
                  draggable
                  onDragStart={(e) => dragStartHandler(e, branch)}
                  onDragLeave={dragLeaveHandler}
                  onDragOver={dragOverHandler}
                  onDrop={(e) => dragDropHandler(e, branch)}
                >
                  <IconWrapper>
                    <Icon className={"icon"} src={`${branch?.iconUrl}`} alt="" />
                    <p>{branch.name}</p>
                  </IconWrapper>
                  <div className="stars">
                    {Array.from(Array(5), (e, i) =>
                      branch?.rating > i ? (
                        <img key={i} src={selectedStar} alt="" />
                      ) : (
                        <img key={i} src={disabledStar} alt="" />
                      )
                    )}
                  </div>
                  <div>
                    {!branch?.disabled && <Edit
                      selected={selected?.id === branch.id}
                      onClick={() => history.push(`/editNetworks#${branch?.id}`)}
                      className="edit"
                    >
                      <img src={selected?.id === branch.id ? blackEdit : edit} alt="edit" />
                    </Edit>}
                    <Trash
                      onClick={() => {
                        setOpen(true);
                        setNetwork(branch);
                      }}
                      className="trash"
                    >
                      <img
                        src={selected?.id === branch.id ? whiteTrash : trash}
                        alt="trash"
                      />
                    </Trash>
                  </div>
                </Row>
              ))}
          </ul>
          {cardList?.find(item => item?.type === 2) && <h2>Ռեստորաններ</h2>}
          <ul className="list">
            {cardList
              ?.filter(item => item.type === 2)
              .sort((a, b) => a.order - b.order).map(branch => (
                <Row
                  index
                  selected={selected?.id === branch?.id}
                  largeRow
                  disabled={branch?.disabled}
                  key={branch.id}
                  className="branchRow"
                  onClick={() => selectBranch(branch)}
                  draggable
                  onDragStart={(e) => dragStartHandler(e, branch)}
                  onDragLeave={dragLeaveHandler}
                  onDragOver={dragOverHandler}
                  onDrop={(e) => dragDropHandler(e, branch)}
                >
                  <IconWrapper>
                    <Icon className={"icon"} src={`${branch?.iconUrl}`} alt="" />
                    <p>{branch.name}</p>
                  </IconWrapper>
                  <div className="stars">
                    {Array.from(Array(5), (e, i) =>
                      branch?.rating > i ? (
                        <img key={i} src={selectedStar} alt="" />
                      ) : (
                        <img key={i} src={disabledStar} alt="" />
                      )
                    )}
                  </div>
                  <div>
                    {!branch?.disabled && <Edit
                      selected={selected?.id === branch.id}
                      onClick={() => history.push(`/editNetworks#${branch?.id}`)}
                      className="edit"
                    >
                      <img src={selected?.id === branch.id ? blackEdit : edit} alt="edit" />
                    </Edit>}
                    <Trash
                      onClick={() => {
                        setOpen(true);
                        setNetwork(branch);
                      }}
                      className="trash"
                    >
                      <img
                        src={selected?.id === branch.id ? whiteTrash : trash}
                        alt="trash"
                      />
                    </Trash>
                  </div>
                </Row>
              ))}
          </ul>
        </div>
        <div>
          {selected && (
            <div>
              <h2>Մասնաճյուղեր «{selected.name}»</h2>
              <ul className="branches">
                {selected?.branches?.map((branch) => (
                  <Row smallRow key={branch.id} index={true}>
                    <div>
                      <p>{branch.address}</p>
                    </div>
                    <div className="stars">
                      {Array.from(Array(5), (e, i) =>
                        branch?.rating > i ? (
                          <img src={selectedStar} alt="img" />
                        ) : (
                          <img src={disabledStar} alt="img" />
                        )
                      )}
                    </div>
                    <div>
                      <Edit
                        onClick={() =>
                          history.push(`/editBranches#${branch?.id}`)
                        }
                        className="edit"
                      >
                        <img src={edit} alt="edit" />
                      </Edit>
                      <Trash
                        onClick={() => {
                          setBranch(branch);
                          setBranchOpen(true);
                        }}
                        className="trash"
                      >
                        <img src={trash} alt="trash" />
                      </Trash>
                    </div>
                  </Row>
                ))}
              </ul>
              <NavigationLink
                to={`/branches/addNew#${selected?.id}`}
                distance={"30px"}
                className="addNew"
              >
                Նոր մասնաճյուղ
              </NavigationLink>
            </div>
          )}
        </div>
      </NetworkWrapper>
    </>
  );
};

export default Networks;
