export const kor = (
  <svg
    width="375"
    height="105"
    viewBox="0 0 375 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-33 1C1.5 2.5 -9 189.5 293.5 56.5C535.5 -49.9 943.167 61.6667 1125.5 102"
      stroke="#FF5757"
    />
  </svg>
);
