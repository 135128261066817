import styled from "styled-components";

export const AdComponent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap:10px;
    & > img {
        object-fit: cover;
        height: ${props => props.small ? '242px' : props.banner ? '144px' : '379px'};
        width: ${props => props.small ? '155px' : props.banner ? '285px' : '243px'};
        border-radius: 4px;
    }
    & > div {
        background: #E2E2E2;
        border-radius: 4px;
        height: ${props => props.small ? '242px' : props.banner ? '144px' : '379px'};
        width: ${props => props.small ? '155px' : props.banner ? '285px' : '243px'};
    }
`;

export const SliderWrapper = styled.div`
    display: flex;
`