import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import trash from "../../pictures/trash.png";
import {useHistory} from "react-router";
import {
    Input,
    PageTitle,
    WrapForm,
    Label,
    SubmitButton,
    FormBody,
    FormHeader,
    SubTitle,
    Loading, FixCheck
} from "../../components/styled";
import {Alert} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const AddNewBranch = ({edit}) => {
    let token = localStorage.getItem('token');
    const [id, setId] = useState(window.location.hash.slice(1))
    const [flags, setFlags] = useState()
    const [count, setCount] = useState(1)
    const [editCount, setEditCount] = useState(0)
    const [halls, setHalls] = useState([])
    const history = useHistory()
    const [branch, setBranch] = useState()
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()

    const deleteHall = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deleteAd = await fetch(`api/Hall/RemoveHall/${id}`, requestOptions)
        deleteAd.status === 200 && getBranch()
    }

    const getBranch = async () => {
        if (edit) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
            };
            const response = await fetch('/api/NetworkFlag/GetFlags', requestOptions)
            const data = await response.json()
            if (response.status === 401) {
                localStorage.clear();
                window.location.reload()
            }
            if (response.status === 500) {
                alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
            }
            setFlags(data.models);

            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors',
            };
            const res = await fetch(`api/Branch/GetBranchForUpdateAdmin?branchId=${id}`, options)
            const branchData = await res.json()
            setHalls([])
            if (res.status === 401) {
                localStorage.clear();
                window.location.reload()
            }
            if (res.status === 500) {
                alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
            }
            setBranch(branchData.model)
            for (const [key, value] of Object.entries(branchData.model.halls)) {
                setHalls(halls => [...halls, value])
            }
        }
    }

    useEffect(() => {
        getBranch()
    }, [])

    useEffect(() => {
        edit && setEditCount(halls.length)
    }, [halls])

    return (
        <>
            {status === "error" ?
                <Alert severity="error">
                    {errorMessage}
                </Alert>
                : status === "loading" &&
                <Loading>
                    <CircularProgress style={{color: "white"}}/>
                </Loading>
            }
            <Formik
                enableReinitialize
                initialValues={{
                    // name: branch?.name || "",
                    address: branch?.address || "",
                    addressAm: branch?.addressAm || "",
                    addressRu: branch?.addressRu || "",
                    userName: branch?.userName || "",
                    // password: branch?.password || "",
                    // branchId: id,
                    networkId: id,
                    longitude: branch?.longitude || "",
                    latitude: branch?.latitude || ""
                }}
                onSubmit={async (values) => {
                    setStatus("loading")

                    let arr = []
                    {
                        edit ?
                            Array.from(Array(editCount), (e, i) => {
                                let obj = {}
                                const id = document.querySelectorAll(`Input[data-id='${i + 1}']`)
                                id.forEach(item => obj[item.getAttribute("data-type")] = item.type === 'checkbox' ? item.checked : item.value)
                                obj.id = Number(id[0]?.getAttribute("data-key"));
                                arr.push(obj)
                            }) :
                            Array.from(Array(count), (e, i) => {
                                let obj = {}
                                const id = document.querySelectorAll(`Input[data-id='${i + 1}']`)
                                id.forEach(item => obj[item.getAttribute("data-type")] = item.type === 'checkbox' ? item.checked : item.value)
                                arr.push(obj)
                            })
                    }

                    let token = localStorage.getItem('token');
                    values["halls"] = arr;
                    edit && (values["branchId"] = id);
                    const requestOptions = {
                        method: edit ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        mode: 'cors',
                        body: JSON.stringify(values)
                    };
                    console.log('values', values);
                    const url = edit ? 'api/AdminAuthenticate/UpdateBranchAdmin' : 'api/AdminAuthenticate/RegisterBranch';
                    const request = await fetch(url, requestOptions)
                    const response = await request.json()
                    if (request.status === 200) {
                        history.push('/networks')
                        setStatus('success')
                    } else if (request.status === 500) {
                        setStatus("error")
                        setErrorMessage("Ցանցային խնդիր։ Խնդրում ենք փորձել կրկին, կամ կապվել Busy- հետ։")
                    } else {
                        setStatus('error')
                        setErrorMessage(response?.message || response?.title)
                    }
                }}>
                {props => (
                    props?.values &&
                    <WrapForm className={"wrap column"} onSubmit={props.handleSubmit}>
                        <FormBody>
                            <FormHeader>
                                <PageTitle>{edit ? 'Փոփոխել մասնաճյուղը' : 'Ավելացնել նոր մասնաճյուղ'}</PageTitle>
                                <SubmitButton className='addNew'
                                              type={"submit"}>{edit ? "Փոփոխել" : "Ավելացնել"}</SubmitButton>
                            </FormHeader>
                            <FormBody noWrap>
                                <FormBody>
                                    <SubTitle className={"subTitle"}>Ընդհանուր ինֆորմացիա</SubTitle>
                                    <Label htmlFor="address">
                                        <span>Հասցե (հայերեն)</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.addressAm}
                                            name={"addressAm"}
                                            Ավելացնել նոր ապրանք={"Մուտքագրեք հասցեն"}
                                            type="text"/>
                                    </Label>
                                    <Label htmlFor="address">
                                        <span>Հասցե (անգլերեն)</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.address}
                                            name={"address"}
                                            Ավելացնել նոր ապրանք={"Մուտքագրեք հասցեն"}
                                            type="text"/>
                                    </Label>
                                    <Label htmlFor="address">
                                        <span>Հասցե (ռուսերեն)</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.addressRu}
                                            name={"addressRu"}
                                            Ավելացնել նոր ապրանք={"Մուտքագրեք հասցեն"}
                                            type="text"/>
                                    </Label>
                                    <Label htmlFor={"username"}>
                                        <span>Գաղտնանուն</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.userName}
                                            name={"userName"}
                                            placeholder={"Մուտքագրեք գաղտնանունը"}
                                            type="text"/>
                                    </Label>

                                    {edit ?
                                        <>
                                            <Label htmlFor="password">
                                                <span>{'Հին գաղտնաբառ'}</span>
                                                <Input
                                                    autoComplete="off"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props?.values?.password}
                                                    name={"oldPassword"}
                                                    placeholder={"Մուտքագրեք գաղտնաբառը"}
                                                    type="text"/>
                                            </Label>
                                            <Label htmlFor="password">
                                                <span>Նոր գաղտնաբառ</span>
                                                <Input
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    value={props?.values?.password}
                                                    name={"newPassword"}
                                                    placeholder={"Մուտքագրեք գաղտնաբառը"}
                                                    type="password"/>
                                            </Label>
                                        </> :
                                        <Label htmlFor="password">
                                            <span>{'Գաղտնաբառ'}</span>
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props?.values?.password}
                                                name={"password"}
                                                placeholder={"Մուտքագրեք գաղտնաբառը"}
                                                type="password"/>
                                        </Label>}
                                    <Label htmlFor="latitude">
                                        <span>Կոորդինատ (լայնություն)</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.latitude}
                                            name={"latitude"}
                                            type="text"/>
                                    </Label>
                                    <Label htmlFor="longitude">
                                        <span>Կոորդինատ (երկայնություն)</span>
                                        <Input
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props?.values?.longitude}
                                            name={"longitude"}
                                            type="text"/>
                                    </Label>
                                </FormBody>
                                <FormBody noWrap>
                                    <SubTitle className={"subTitle"}>Սրահներ</SubTitle>
                                    <Label halls full htmlFor={"halls"}>
                                        <span>Տեսակներ</span>
                                        {edit ?
                                            <>
                                                {halls?.map((hall, i) =>
                                                    <div className="d-flex">
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={hall.id}
                                                            defaultValue={hall?.nameHy}
                                                            data-id={i + 1}
                                                            data-type={'nameHy'}
                                                            placeholder={"Սրահի անվանումը հայերեն"}
                                                            type="text"/>
                                                        <Input
                                                            distance
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={hall.id}
                                                            defaultValue={hall?.nameRu}
                                                            data-id={i + 1}
                                                            data-type={'nameRu'}
                                                            placeholder={"Սրահի անվանում ռուսերեն"}
                                                            type="text"/>
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={hall.id}
                                                            defaultValue={hall?.nameEn}
                                                            data-type={'nameEn'}
                                                            data-id={i + 1}
                                                            placeholder={"Սրահի անվանում անգլերեն"}
                                                            type="text"/>
                                                        <FixCheck>
                                                            <input
                                                                type="checkbox"
                                                                onChange={props.handleChange}
                                                                name={"halls"}
                                                                data-type={'needsEndTime'}
                                                                data-id={i + 1}
                                                                checked={halls[i]?.needsEndTime}
                                                            />
                                                            <span>Ելքի ժամով</span>
                                                        </FixCheck>
                                                        <img onClick={() => deleteHall(hall.id)} src={trash} alt=""/>
                                                    </div>
                                                )}
                                                {editCount > halls?.length && Array.from(Array(editCount), (e, i) =>
                                                    halls?.length < i + 1 &&
                                                    <div className="d-flex">
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={0}
                                                            data-id={i + 1}
                                                            data-type={'nameHy'}
                                                            placeholder={"Սրահի անվանումը հայերեն"}
                                                            type="text"/>
                                                        <Input
                                                            distance
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={0}
                                                            data-id={i + 1}
                                                            data-type={'nameRu'}
                                                            placeholder={"Սրահի անվանում ռուսերեն"}
                                                            type="text"/>
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-key={0}
                                                            data-type={'nameEn'}
                                                            data-id={i + 1}
                                                            placeholder={"Սրահի անվանում անգլերեն"}
                                                            type="text"/>
                                                        <FixCheck>
                                                            <input
                                                                type="checkbox"
                                                                onChange={props.handleChange}
                                                                name={"halls"}
                                                                data-type={'needsEndTime'}
                                                                data-id={i + 1}
                                                                checked={halls[i]?.needsEndTime}
                                                            />
                                                            <span>Ելքի ժամով</span>
                                                        </FixCheck>
                                                        <img onClick={() => setEditCount(editCount - 1)} src={trash}
                                                             alt="trash"/>
                                                    </div>
                                                )}
                                            </> :
                                            <>
                                                {Array.from(Array(count), (e, i) =>
                                                    count > i &&
                                                    <div className="d-flex">
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-id={i + 1}
                                                            data-type={'nameHy'}
                                                            placeholder={"Սրահի անվանումը հայերեն"}
                                                            type="text"/>
                                                        <Input
                                                            distance
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-id={i + 1}
                                                            data-type={'nameRu'}
                                                            placeholder={"Սրահի անվանում ռուսերեն"}
                                                            type="text"/>
                                                        <Input
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                            name={"halls"}
                                                            data-type={'nameEn'}
                                                            data-id={i + 1}
                                                            placeholder={"Սրահի անվանում անգլերեն"}
                                                            type="text"/>
                                                        <FixCheck>
                                                            <input
                                                                type="checkbox"
                                                                onChange={props.handleChange}
                                                                name={"halls"}
                                                                data-type={'needsEndTime'}
                                                                data-id={i + 1}
                                                                checked={halls[i]?.needsEndTime}
                                                            />
                                                            <span>Ելքի ժամով</span>
                                                        </FixCheck>
                                                        {count === i + 1 &&
                                                            <img onClick={() => setCount(count - 1)} src={trash}
                                                                 alt=""/>}
                                                    </div>
                                                )}
                                            </>
                                        }
                                        <span onClick={() => edit ? setEditCount(editCount + 1) : setCount(count + 1)}
                                              className={"red"}>+ նոր տարբերակ</span>
                                    </Label>
                                </FormBody>
                            </FormBody>
                        </FormBody>
                    </WrapForm>
                )}
            </Formik>
        </>
    )
};

export default AddNewBranch