import React, {useEffect, useState} from "react";
import "./styled.scss"
import edit from "../../pictures/edit.png";
import Pagination from '@mui/material/Pagination';
import filledTrash from "../../pictures/filledTrash.svg";
import {useHistory} from "react-router";
import {Edit, PageTitle, SubmitButton, Trash} from "../../components/styled";
import {Modal} from "@mui/material";

const Items = () => {
    let token = localStorage.getItem('token');
    const userId = localStorage.getItem("userId")
    const history = useHistory()
    const [items, setItems] = useState()
    const [total, setTotal] = useState()
    const [open, setOpen] = useState()
    const [item, setItem] = useState()
    const [currentPage, setCurrentPage] = useState(1)

    const getItems = async () => {
        setItems([])
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deletedCategory = await fetch(`api/Network/GetMenu?networkId=${userId}&pageNumber=${currentPage}&count=9`, requestOptions)
        const categoryData = await deletedCategory.json();
        if(deletedCategory.status === 401){
            localStorage.clear();
            window.location.reload()
        }
        deletedCategory.status === 200 && setItems(categoryData.model.items)
        setTotal(categoryData.model.totalPages)
    }
    useEffect(() => {
        getItems()
    }, [currentPage])

    const deleteItem = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deleteAd = await fetch(`api/Item/RemoveItem/${id}`, requestOptions)
        deleteAd.status === 200 && getItems()
        setOpen(false)
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={()=>setOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <div className={"modalWindow"}>
                    <div className="modalBox">
                        {`Ջնջե՞լ ${item?.name || item?.category} `}
                        <div>
                            <SubmitButton className='addNew' onClick={()=>deleteItem(item?.id)}>Ջնջել</SubmitButton>
                            <SubmitButton className='addNew cancelButton' onClick={()=>setOpen(false)}>Զեղարկել</SubmitButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className={"pageHeader"}>
                <PageTitle>Տեսականի</PageTitle>
                <SubmitButton onClick={()=> history.push('/items/addNew')} className='addNew' type={"button"}>{"Ավելացնել"}</SubmitButton>
            </div>
            <div className={"items"}>
                {items?.map(item =>
                    <div className="item">
                        <img src={item?.picUrl} alt=""/>
                        <div className={"info"}>
                            <div>
                                <h5>{item.name}</h5>
                                <p>{item.category}</p>
                            </div>
                            <div>
                                <p>{item.ingredients}</p>
                            </div>
                            <div className={"prices"}>
                                <div>
                                    {item.discountedPrice == 0 ?
                                        <span className={`discountedPrice`}>{item.price} AMD</span>
                                        :
                                        <>
                                            <span className={`price line`}>{item.price} AMD</span>
                                            <span className={"discountedPrice"}>{item.discountedPrice} AMD</span>
                                        </>
                                    }
                                </div>
                                <div>
                                    <Edit onClick={()=>history.push(`/editItem#${item.id}`)} className='edit'>
                                        <img src={edit}/>
                                    </Edit>
                                    <Trash onClick={()=>{setItem(item); setOpen(true)}} className='trash'>
                                        <img src={filledTrash}/>
                                    </Trash>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Pagination onChange={(e, page) => {
                setCurrentPage(page)
            }} count={total} shape="rounded"/>
        </div>
    )
};

export default Items