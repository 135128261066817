import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import filledTrash from "../../pictures/filledTrash.svg";
import "./styled.scss"
import { Edit, FormBody, Input, Label, PageTitle, Row, SubmitButton, Trash, WrapForm } from "../../components/styled";
import { List } from "../Networks/styled";
import { Modal } from "@mui/material";
import editIcon from "../../pictures/edit.png";

const Categories = () => {
    let token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem("userId");
    const items = window.location.pathname.includes('items')
    const [categories, setCategories] = useState()
    const [open, setOpen] = useState(false)
    const [category, setCategory] = useState()
    const [edit, setEdit] = useState(false)
    const [status, setStatus] = useState()
    const [selected, setSelected] = useState([])

    const getCategories = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const getCategories = await fetch(role === "Network" ? `api/Network/GetCategories?networkId=${userId}` : `api/NetworkFlag/GetFlags`, requestOptions)
        const categoriesData = await getCategories.json();
        if (getCategories.status === 401) {
            localStorage.clear();
            window.location.reload()
        }
        if (getCategories.status === 500) {
            alert("Տեխնիկական խնդիր, խնդրում ենք փորձել ավելի ուշ։")
        }
        getCategories.status === 200 && setCategories(categoriesData.models)
    }

    const addNew = () => {
        setEdit(false);
        setSelected({})
    }

    const deleteCategory = async (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            mode: 'cors',
        };
        const deletedCategory = await fetch(items ? `api/Network/DeleteItemCategory/${id}` : `api/NetworkFlag/DeleteFlag?id=${id}`, requestOptions)
        deletedCategory.status === 200 && await getCategories()
        setOpen(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            await getCategories();
        };
        fetchData();
    }, []);
    useEffect(() => {
        setCardList(categories ? [...categories] : [])
    }, [categories])
    const [cardList, setCardList] = useState([])
    const [currentCategory, setCurrentCategory] = useState(null)
    function dragStartHandler(e, category) {
        setCurrentCategory(category)
    }
    const saveNetworkItemCategoriesOrder = async (changeList) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(changeList)
        };
        await fetch(
            "api/Network/SaveNetworkItemCategoriesOrder",
            requestOptions,
        );
    }
    function dragDropHandler(e, category) {
        e.preventDefault()
        e.target.style.background = '#F2F2F2'
        let changeList = cardList.map(item => {
            if (item.id === category.id) {
                return { ...item, order: currentCategory.order }
            }
            if (item.id === currentCategory.id) {
                return { ...item, order: category.order }
            }
            return item
        })
        setCardList(changeList)
        saveNetworkItemCategoriesOrder(changeList)
    }
    function dragOverHandler(e) {
        e.preventDefault()
        e.target.style.background = 'lightgray'
    }

    function dragLeaveHandler(e) {
        e.target.style.background = '#F2F2F2'
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <div className={"modalWindow"}>
                    <div className="modalBox">
                        {`Ջնջե՞լ ${category?.name || category?.category} կատեգորիան:`}
                        <div>
                            <SubmitButton className='addNew' onClick={() => deleteCategory(category?.id)}>Ջնջել</SubmitButton>
                            <SubmitButton className='addNew cancelButton' onClick={() => setOpen(false)}>Զեղարկել</SubmitButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <PageTitle>Կատեգորիաներ</PageTitle>
            <div className={"categories"}>
                <List smallRow className="list large w-100">
                    {/* {categories && categories[0]?.category &&
                        <Row smallRow>
                            <p>{`${categories[0]?.category}, ${categories[0]?.categoryEn}, ${categories[0]?.categoryRu}`}</p>
                        </Row>
                    } */}
                    {cardList?.sort((a, b) => a.order - b.order).map((category, index) =>
                        category.id !== 0 &&
                        <Row
                            key={category.id}
                            smallRow
                            index={index}
                            draggable={items}
                            onDragStart={(e) => dragStartHandler(e, category)}
                            onDragLeave={dragLeaveHandler}
                            onDragOver={dragOverHandler}
                            onDrop={(e) => dragDropHandler(e, category)}
                        >
                            <p>{category?.name ? `${category?.name}, ${category?.nameEn}, ${category?.nameRu}` : `${category?.categoryHy}, ${category?.categoryEn}, ${category?.categoryRu}`}</p>
                            <div>
                                {index !== 0 && <Edit onClick={() => {
                                    setSelected(category);
                                    setEdit(true)
                                }} className='edit'>
                                    <img src={editIcon} alt='edit' />
                                </Edit>}
                                {index !== 0 && <Trash onClick={() => { setCategory(category); setOpen(true) }} className='trash'>
                                    <img src={filledTrash} alt='trash' />
                                </Trash>}
                            </div>
                        </Row>
                    )}
                </List>
                {edit ?
                    <div className={"newCategory"}>
                        <h3>Փոփոխել կատեգորիան</h3>
                        <Formik
                            enableReinitialize
                            initialValues={items ? {
                                category: selected?.category || selected?.name || '',
                                categoryEn: selected?.categoryEn || selected?.nameEn || '',
                                categoryRu: selected?.categoryRu || selected?.nameRu || ''
                            } :
                                {
                                    name: selected?.category || selected?.name || '',
                                    nameEn: selected?.categoryEn || selected?.nameEn || '',
                                    nameRu: selected?.categoryRu || selected?.nameRu || ''
                                }}
                            onSubmit={async (values) => {
                                setStatus("loading")
                                items && (values["networkId"] = Number(userId))
                                values["id"] = Number(selected?.id)
                                const requestOptions = {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    },
                                    body: JSON.stringify(values)
                                };
                                const addedCategory = await fetch(items ? `api/Network/UpdateItemCategory` : `api/NetworkFlag/UpdateFlag`, requestOptions);
                                const categoryData = await addedCategory.json();
                                if (addedCategory.status === 200) {
                                    setStatus("success")
                                    setCategories(categoryData.models)
                                    window.location.reload()
                                } else {
                                    setStatus("error")
                                }
                            }}
                        >
                            {props =>
                                <WrapForm className={"wrap category"} onSubmit={props.handleSubmit}>
                                    <FormBody>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը հայերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props?.values?.category : props?.values?.name}
                                                name={items ? "category" : "name"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը ռուսերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props?.values?.categoryRu : props?.values?.nameRu}
                                                name={items ? "categoryRu" : "nameRu"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը անգլերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props?.values?.categoryEn : props?.values?.nameEn}
                                                name={items ? "categoryEn" : "nameEn"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                    </FormBody>
                                    <SubmitButton distance={"30px"} className='addNew' type={"submit"}>{"Թարմացնել"}</SubmitButton>
                                    <p onClick={() => addNew()} style={{ marginLeft: "40px", fontSize: "14px", marginTop: "10px", textDecoration: "underline", cursor: "pointer" }}>Ավելացնել նոր կատեգորիա</p>
                                </WrapForm>
                            }
                        </Formik>
                    </div> :
                    <div className={"newCategory"}>
                        <h3>Ավելացնել նոր կատեգորիա</h3>
                        <Formik
                            initialValues={{
                            }}
                            onSubmit={async (values) => {
                                items && (values["networkId"] = Number(userId))
                                const requestOptions = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    },
                                    body: JSON.stringify(values)
                                };
                                const addedCategory = await fetch(items ? `api/Network/AddItemCategory` : `api/NetworkFlag/AddFlag`, requestOptions)
                                const categoryData = await addedCategory.json();
                                if (addedCategory.status === 200) {
                                    setCategories(categoryData.models)
                                    window.location.reload()
                                }

                            }}
                        >
                            {props =>
                                <WrapForm className={"wrap category"} onSubmit={props.handleSubmit}>
                                    <FormBody>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը հայերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props.values.category : props.values.name}
                                                name={items ? "category" : "name"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը ռուսերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props.values.categoryRu : props.values.nameRu}
                                                name={items ? "categoryRu" : "nameRu"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                        <Label threeOnRow htmlFor="name">Կատեգորիայի անունը անգլերեն
                                            <Input
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={items ? props.values.categoryEn : props.values.nameEn}
                                                name={items ? "categoryEn" : "nameEn"}
                                                placeholder={"Գրեք կատեգորիայի անունը"}
                                                type="text" />
                                        </Label>
                                    </FormBody>
                                    <SubmitButton distance={"30px"} className='addNew' type={"submit"}>{status === "loading" ? "Բեռնում․․․" : "Ավելացնել"}</SubmitButton>
                                </WrapForm>
                            }
                        </Formik>
                    </div>}

            </div>
        </>
    )
}

export default Categories